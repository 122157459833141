import React from 'react'
import { Grid, Paper } from '@material-ui/core'
import { IParticipantData, ISiteConfig } from '@models/index'
import ParticipantDetails from '@components/Participant/ParticipantDetails'
import Graph from '@components/Graph/Graph'
import ClickableParticipant from './ClickableParticipant'

interface GraphViewProps {
  participants: IParticipantData[]
  siteConfig: ISiteConfig
  onParticipantClick: (id: string) => void
}

const DashboardGraphView: React.FunctionComponent<GraphViewProps> = ({
  participants,
  siteConfig,
  onParticipantClick,
}) => {
  if (participants.length === 0) {
    return <div>No Patients</div>
  }
  return (
    <Grid container spacing={2}>
      {participants.map((participant) => (
        <Grid key={participant.id} item xs={12} sm={6} lg={4} xl={3}>
          <Paper>
            <ClickableParticipant
              pid={participant.id}
              onClick={onParticipantClick}
            >
              <ParticipantDetails
                participant={participant}
                siteConfig={siteConfig}
              >
                <Graph
                  egvs={participant.allEgvs}
                  highThresh={siteConfig.highalertthreshold}
                  lowThresh={siteConfig.lowalertthreshold}
                />
              </ParticipantDetails>
            </ClickableParticipant>
          </Paper>
        </Grid>
      ))}
    </Grid>
  )
}

export default DashboardGraphView
