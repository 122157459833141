import React, { useEffect, useState } from 'react'
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListSubheader from '@material-ui/core/ListSubheader'
import ListItem from '@material-ui/core/ListItem'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Divider from '@material-ui/core/Divider'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import { ISiteConfig } from '@models/index'

type EventsSettingsState = {
  hypereventtime: string
  hypoeventtime: string
  mineventtime: string
  lowalertthreshold: string
  highalertthreshold: string
}

type EventsSettingsProps = {
  hypereventtime: number
  hypoeventtime: number
  mineventtime: number
  lowalertthreshold: number
  highalertthreshold: number
  onsave: (settings: ISiteConfig) => void
}

const useStyles = makeStyles(
  createStyles({
    alerts: {
      width: 200,
    },
    events: {
      width: 275,
    },
    eventsMin: {
      width: 150,
    },
  })
)

const hasChanged = (
  current: EventsSettingsProps,
  next: EventsSettingsState
): boolean => {
  return (
    `${current.hypereventtime}` !== next.hypereventtime ||
    `${current.hypoeventtime}` !== next.hypoeventtime ||
    `${current.mineventtime}` !== next.mineventtime ||
    `${current.lowalertthreshold}` !== next.lowalertthreshold ||
    `${current.highalertthreshold}` !== next.highalertthreshold
  )
}

const validateLowAlert = (threshold: number, upperLimit: number) => {
  return threshold > 60 && threshold < upperLimit
}

const validateHighAlert = (threshold: number, lowerLimit: number) => {
  return threshold < 400 && threshold > lowerLimit
}

const validateHypoEvents = (threshold: number) => {
  return threshold > 0
}

const validateHyperEvents = (threshold: number) => {
  return threshold > 0
}

const validateMinTimeEvents = (threshold: number) => {
  return threshold > 5
}

const valid = (data: EventsSettingsState) => {
  return (
    validateLowAlert(
      parseInt(data.lowalertthreshold),
      parseInt(data.highalertthreshold)
    ) &&
    validateHighAlert(
      parseInt(data.highalertthreshold),
      parseInt(data.lowalertthreshold)
    ) &&
    validateHypoEvents(parseInt(data.hypoeventtime)) &&
    validateHyperEvents(parseInt(data.hypereventtime)) &&
    validateMinTimeEvents(parseInt(data.mineventtime))
  )
}

export default function EventsSettings(props: EventsSettingsProps) {
  const [state, setState] = useState<EventsSettingsState>({
    hypereventtime: `${props.hypereventtime}`,
    hypoeventtime: `${props.hypoeventtime}`,
    mineventtime: `${props.mineventtime}`,
    lowalertthreshold: `${props.lowalertthreshold}`,
    highalertthreshold: `${props.highalertthreshold}`,
  })
  const [canSave, setCanSave] = useState(false)
  const classes = useStyles()
  const theme = useTheme()

  useEffect(() => {
    if (hasChanged(props, state) && valid(state)) {
      setCanSave(true)
    } else {
      setCanSave(false)
    }
  }, [state, props])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => {
      return {
        ...prevState,
        [event.target.id]: event.target.value,
      }
    })
  }
  const save = () => {
    props.onsave({
      hypereventtime: parseInt(state.hypereventtime),
      hypoeventtime: parseInt(state.hypoeventtime),
      mineventtime: parseInt(state.mineventtime),
      lowalertthreshold: parseInt(state.lowalertthreshold),
      highalertthreshold: parseInt(state.highalertthreshold),
    } as ISiteConfig)
  }

  const helpText = {
    lowAlert: `Low Alerts must be between 60 mg/dL and ${state.highalertthreshold} mg/dL.`,
    highAlert: `High Alerts must be between ${state.lowalertthreshold} mg/dL and 400 mg/dL.`,
    eventSettings: 'Events settings must be greater than 0 minutes.',
    minEventSettings:
      'Minimum Time Between Events must be greater than 5 minutes.',
  }

  return (
    <Card elevation={1}>
      <CardHeader
        title="Alert Settings"
        action={
          <Button
            color="primary"
            variant="contained"
            style={{ fontSize: 11, textTransform: 'initial' }}
            onClick={save}
            disabled={!canSave}
          >
            Save
          </Button>
        }
      />
      <Divider />
      <CardContent>
        <List
          subheader={
            <ListSubheader>Enter the alert ranges below.</ListSubheader>
          }
        >
          <ListItem>
            <label>
              Based on guidelines set by the American Diabetes Association in
              2019, the Hypo/Hyper ranges are between <b>70 mg/dL</b>{' '}
              (hypoglycemic) and <b>180 mg/dL</b> (hyperglycemic).
            </label>
          </ListItem>
        </List>
        <Divider />
        <List
          subheader={<ListSubheader>Alert Thresholds</ListSubheader>}
          style={{ backgroundColor: theme.palette.background.default }}
        >
          <ListItem>
            <TextField
              label="Low Alerts"
              error={
                !validateLowAlert(
                  parseInt(state.lowalertthreshold),
                  parseInt(state.highalertthreshold)
                )
              }
              className={classes.alerts}
              id="lowalertthreshold"
              value={state.lowalertthreshold}
              size="small"
              variant="outlined"
              type="number"
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">mg/dL</InputAdornment>
                ),
              }}
              helperText={
                validateLowAlert(
                  parseInt(state.lowalertthreshold),
                  parseInt(state.highalertthreshold)
                )
                  ? undefined
                  : helpText.lowAlert
              }
            />
          </ListItem>
          <ListItem>
            <TextField
              label="High Alerts"
              error={
                !validateHighAlert(
                  parseInt(state.highalertthreshold),
                  parseInt(state.lowalertthreshold)
                )
              }
              className={classes.alerts}
              id="highalertthreshold"
              value={state.highalertthreshold}
              size="small"
              variant="outlined"
              type="number"
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">mg/dL</InputAdornment>
                ),
              }}
              helperText={
                validateHighAlert(
                  parseInt(state.highalertthreshold),
                  parseInt(state.lowalertthreshold)
                )
                  ? undefined
                  : helpText.highAlert
              }
            />
          </ListItem>
        </List>
        <Divider />
        <List subheader={<ListSubheader>Events</ListSubheader>}>
          <ListItem>
            <label>Hypoglycemic Events</label>
          </ListItem>
          <ListItem>
            <TextField
              error={!validateHypoEvents(parseInt(state.hypoeventtime))}
              className={classes.events}
              id="hypoeventtime"
              value={state.hypoeventtime}
              size="small"
              variant="outlined"
              type="number"
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    minutes under 70 mg/dL
                  </InputAdornment>
                ),
              }}
              helperText={
                validateHypoEvents(parseInt(state.hypoeventtime))
                  ? undefined
                  : helpText.eventSettings
              }
            />
          </ListItem>
          <ListItem>
            <label>Hyperglycemic Events</label>
          </ListItem>
          <ListItem>
            <TextField
              error={!validateHyperEvents(parseInt(state.hypereventtime))}
              className={classes.events}
              id="hypereventtime"
              value={state.hypereventtime}
              size="small"
              variant="outlined"
              type="number"
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    minutes above 180 mg/dL
                  </InputAdornment>
                ),
              }}
              helperText={
                validateHyperEvents(parseInt(state.hypereventtime))
                  ? undefined
                  : helpText.eventSettings
              }
            />
          </ListItem>
          <ListItem>
            <label>Minimum Time Between Events</label>
          </ListItem>
          <ListItem>
            <TextField
              error={!validateMinTimeEvents(parseInt(state.mineventtime))}
              className={classes.eventsMin}
              id="mineventtime"
              value={state.mineventtime}
              size="small"
              variant="outlined"
              type="number"
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">minutes</InputAdornment>
                ),
              }}
              helperText={
                validateMinTimeEvents(parseInt(state.mineventtime))
                  ? undefined
                  : helpText.minEventSettings
              }
            />
          </ListItem>
        </List>
      </CardContent>
    </Card>
  )
}
