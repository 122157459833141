import React, { useEffect, useState } from 'react'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import CardContent from '@material-ui/core/CardContent'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import TextField from '@material-ui/core/TextField'
import { IMetaData, ISiteConfig } from '@models/index'

type ParticipantSettingsState = {
  metas: Array<IMetaData>
}

type ParticipantSettingsProps = {
  metas: Array<IMetaData>
  onsave: (settings: ISiteConfig) => void
}

const hasChanged = (
  current: ParticipantSettingsProps,
  next: ParticipantSettingsState
): boolean => {
  return (
    current.metas[0].displayname !== next.metas[0].displayname ||
    current.metas[1].displayname !== next.metas[1].displayname ||
    current.metas[2].displayname !== next.metas[2].displayname
  )
}

const validateLabels = (data: string) => {
  return data !== ''
}

const valid = (data: ParticipantSettingsState) => {
  return (
    validateLabels(data.metas[0].displayname) &&
    validateLabels(data.metas[1].displayname) &&
    validateLabels(data.metas[2].displayname)
  )
}

export default function ParticipantSettings(props: ParticipantSettingsProps) {
  const [state, setState] = useState<ParticipantSettingsState>({
    metas: props.metas.map((m) => ({ ...m })),
  })
  const [canSave, setCanSave] = useState(false)

  useEffect(() => {
    if (hasChanged(props, state) && valid(state)) {
      setCanSave(true)
    } else {
      setCanSave(false)
    }
  }, [state, props])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const index = parseInt(event.target.id)
    setState((prevState) => {
      const newMetas = [...prevState.metas]
      newMetas[index].displayname = event.target.value
      return {
        ...prevState,
        metas: newMetas,
      }
    })
  }

  const save = () => {
    props.onsave(state as ISiteConfig)
  }

  const helpText = {
    emptyField: 'Labels cannot be blank.',
  }

  return (
    <Card>
      <CardHeader
        title="Participant Field Labels"
        action={
          <Button
            color="primary"
            variant="contained"
            style={{ fontSize: 11, textTransform: 'initial' }}
            onClick={save}
            disabled={!canSave}
          >
            Save
          </Button>
        }
      />
      <Divider />
      <CardContent>
        <List>
          <ListItem>
            <TextField
              error={!validateLabels(state.metas[0].displayname)}
              label="Label 1"
              id="0"
              size="small"
              variant="outlined"
              value={state.metas[0].displayname}
              onChange={handleChange}
              helperText={
                validateLabels(state.metas[0].displayname)
                  ? undefined
                  : helpText.emptyField
              }
            />
          </ListItem>
          <ListItem>
            <TextField
              error={!validateLabels(state.metas[1].displayname)}
              label="Label 2"
              id="1"
              size="small"
              variant="outlined"
              value={state.metas[1].displayname}
              onChange={handleChange}
              helperText={
                validateLabels(state.metas[1].displayname)
                  ? undefined
                  : helpText.emptyField
              }
            />
          </ListItem>
          <ListItem>
            <TextField
              error={!validateLabels(state.metas[2].displayname)}
              label="Label 3"
              id="2"
              size="small"
              variant="outlined"
              value={state.metas[2].displayname}
              onChange={handleChange}
              helperText={
                validateLabels(state.metas[2].displayname)
                  ? undefined
                  : helpText.emptyField
              }
            />
          </ListItem>
        </List>
      </CardContent>
    </Card>
  )
}
