import React from 'react'
import { useTheme } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Spinner from '@components/Spinner/Spinner'
import useUsers from '@providers/data/hooks/useUsers'
import useSiteConfig from '@providers/data/hooks/useSiteConfig'
import { UsersListView } from './UsersListView'
import CreateUserModalView from './CreateUserModalView'
import { useKeycloak } from '@providers/auth'
import ResetPasswordModalView from './ResetPasswordModalView'
import useResetPassword from '@providers/data/hooks/useResetPassword'

export default function UsersHome() {
  const theme = useTheme()
  const { token } = useKeycloak()
  const { siteConfig } = useSiteConfig()
  const { users, newUser } = useUsers()
  const [open, setOpen] = React.useState(false)
  const password = useResetPassword(token)

  if (users.length === 0) {
    return <Spinner />
  }

  const openModal = () => {
    setOpen(true)
  }

  const closeModal = () => {
    setOpen(false)
  }

  const handleUserCreate = (
    username: string,
    password: string,
    role: string
  ) => {
    newUser(username, password, role)
    setOpen(false)
  }

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12} style={{ marginTop: 10, marginBottom: 10 }}>
          <Card
            style={{
              backgroundColor: theme.palette.background.paper,
              marginBottom: 10,
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                <Grid container alignItems={'center'}>
                  <Grid item xs={9} sm={10} md={11}>
                    <Typography
                      style={{
                        padding: '10px',
                        color: theme.oppositePalette.colorInverted,
                      }}
                    >
                      Users at {siteConfig ? siteConfig.title : ''}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} sm={2} md={1}>
                    <Button
                      color="primary"
                      variant="contained"
                      style={{
                        fontSize: 11,
                        textTransform: 'initial',
                        width: '90%',
                      }}
                      onClick={openModal}
                    >
                      New User
                    </Button>
                    <CreateUserModalView
                      open={open}
                      addDashboardUser={handleUserCreate}
                      handleClose={closeModal}
                    ></CreateUserModalView>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Divider />
            <Grid
              container
              justify={'center'}
              style={{ marginBottom: '1em', marginTop: '1em' }}
            >
              <Grid item xs={12} style={{ maxWidth: '98%' }}>
                <UsersListView
                  users={users}
                  onUserClick={(id: string) => {
                    password.handleResetPassword(id)
                  }}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
      <ResetPasswordModalView
        open={password.open}
        password={password.newPassword}
        onResetPassword={password.submitResetPassword}
        onClose={password.closeResetPasswordModal}
      ></ResetPasswordModalView>
    </React.Fragment>
  )
}
