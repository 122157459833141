import { EGVRecord, IAcknowledgement, IAlarm, IParticipantRecord, ISiteConfig } from "@models/index"
import {
  Action,
  alarmUpdateAction,
  allAcknowledgementsAction,
  allEgvsAction,
  newAcknowledgementAction,
  newEgvsAction,
  participantUpdateAction,
  siteConfigUpdateAction
} from "../actions"
import { useGlobalState } from "./useGlobalState"

const collectEgvs = (dispatch: React.Dispatch<Action>) => (egvs: Record<string, EGVRecord[]>, allEgvs?: boolean) => {
  if (allEgvs) {
    dispatch(allEgvsAction(egvs))
  } else {
    dispatch(newEgvsAction(egvs))
  }
}

const collectParticipants = (dispatch: React.Dispatch<Action>) => (participants: IParticipantRecord[]) => {
  dispatch(participantUpdateAction(participants))
}

const collectAlarms = (dispatch: React.Dispatch<Action>) => (alarms: Record<string, IAlarm[]>) => {
  dispatch(alarmUpdateAction(alarms))
}

const collectAcknowledgements = (dispatch: React.Dispatch<Action>) => (acks: IAcknowledgement[]) => {
  dispatch(allAcknowledgementsAction(acks))
}

const collectSiteConfig = (dispatch: React.Dispatch<Action>) => (config: ISiteConfig) => {
  dispatch(siteConfigUpdateAction(config))
}

const collectNewAcknowledgement = (dispatch: React.Dispatch<Action>) => (ack: IAcknowledgement) => {
  dispatch(newAcknowledgementAction(ack))
}

export default () => {
  const { dispatch } = useGlobalState()

  return {
    collectEgvs: collectEgvs(dispatch),
    collectParticipants: collectParticipants(dispatch),
    collectAlarms: collectAlarms(dispatch),
    collectAcknowledgements: collectAcknowledgements(dispatch),
    collectNewAcknowledgement: collectNewAcknowledgement(dispatch),
    collectSiteConfig: collectSiteConfig(dispatch)
  }
}
