import React, { createContext, useEffect, useState } from 'react'
import { io, Socket } from 'socket.io-client'
import { useKeycloak } from '@providers/auth'
import useDataActions from '@providers/data/hooks/useDataActions'
import { REACT_APP_REST_SERVICE_URL } from '@config/env'
import {
  EGVRecord,
  IAcknowledgement,
  IAlarm,
  IParticipantRecord,
  ISiteConfig,
} from '@models/index'

enum MessageType {
  ALL_EGVS = 'ALL_EGVS',
  NEW_EGVS = 'NEW_EGVS',
  ALARM = 'ALARM',
  PARTICIPANT = 'PARTICIPANT',
  SITE_CONFIG = 'SITE_CONFIG',
  ACKNOWLEDGEMENTS = 'ACKNOWLEDGEMENTS',
}

const WebsocketContext = createContext(null)

export { WebsocketContext }

const WebsocketProvider: React.FC = ({ children }) => {
  const [socket, setSocket] = useState<Socket | null>(null)
  const { token } = useKeycloak()
  const {
    collectEgvs,
    collectAlarms,
    collectAcknowledgements,
    collectParticipants,
    collectSiteConfig,
  } = useDataActions()

  useEffect(() => {
    if (!socket) {
      const url = new URL(REACT_APP_REST_SERVICE_URL)
      const _socket = io(`${url.origin}`, {
        path: url.pathname !== '/' ? `${url.pathname}/socket.io` : '',
        auth: {
          token: token,
        },
      })

      _socket.on(MessageType.ALL_EGVS, (data: Record<string, EGVRecord[]>) => {
        collectEgvs(data, true)
      })

      _socket.on(MessageType.NEW_EGVS, (data: Record<string, EGVRecord[]>) => {
        collectEgvs(data)
      })

      _socket.on(MessageType.ALARM, (data: Record<string, IAlarm[]>) => {
        //console.log(data)
        collectAlarms(data)
      })

      _socket.on(MessageType.PARTICIPANT, (data: IParticipantRecord[]) => {
        collectParticipants(data)
      })

      _socket.on(MessageType.SITE_CONFIG, (msg: ISiteConfig) => {
        //console.log(msg)
        collectSiteConfig(msg)
      })

      _socket.on(MessageType.ACKNOWLEDGEMENTS, (msg: IAcknowledgement[]) => {
        console.log(msg)
        collectAcknowledgements(msg)
      })

      setSocket(_socket)
    }
  }, [
    socket,
    token,
    collectEgvs,
    collectParticipants,
    collectAlarms,
    collectSiteConfig,
    collectAcknowledgements,
  ])

  // update auth reconnect when token changes
  useEffect(() => {
    if (socket) {
      socket.off('connect_error')
      socket.on('connect_error', (err) => {
        console.log(err.message)
        if (err.message === 'invalid credentials') {
          socket.auth = { token: token }
          socket.connect()
        }
      })
    }
  }, [socket, token])

  return (
    <WebsocketContext.Provider value={null}>
      {children}
    </WebsocketContext.Provider>
  )
}

export default WebsocketProvider
