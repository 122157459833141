import { Grid } from '@material-ui/core'
import React from 'react'
import { useHistory } from 'react-router'

import useSiteConfig from '@providers/data/hooks/useSiteConfig'
import useUserConfig from '@providers/data/hooks/useUserConfig'
import { DashboardView } from '@models/index'
import DischargeDialog from '@components/Discharge/DischargeDialog'
import Spinner from '@components/Spinner/Spinner'

import DashboardCardView from './DashboardCardView'
import DashboardGraphView from './DashboardGraphView'
import { DashboardListView } from './DashboardListView'
import DashboardViewToggle from './DashboardViewToggle'
import Grouping from './Grouping'
import { useSortedGroups } from './useParticipantInfo'

const Dashboard = () => {
  const history = useHistory()
  const { userConfig } = useUserConfig()
  const { siteConfig } = useSiteConfig()
  const { groupedParticipants, sortBy, setCustomSort } = useSortedGroups()

  const goToParticipant = (id: string) => {
    history.push('/participant/' + id)
  }

  if (!groupedParticipants || !siteConfig) {
    return <Spinner />
  }

  const selectView = (dashboardView: string) => {
    switch (dashboardView) {
      case DashboardView.LIST_VIEW:
        return {
          priority: (
            <DashboardListView
              siteConfig={siteConfig}
              participants={groupedParticipants.priority}
              onParticipantClick={goToParticipant}
            />
          ),
          inRange: (
            <DashboardListView
              siteConfig={siteConfig}
              participants={groupedParticipants.inRange}
              onParticipantClick={goToParticipant}
            />
          ),
          inactive: (
            <DashboardListView
              siteConfig={siteConfig}
              participants={groupedParticipants.inactive}
              onParticipantClick={goToParticipant}
            />
          ),
        }
      case DashboardView.CARD_VIEW:
        return {
          priority: (
            <DashboardCardView
              participants={groupedParticipants.priority}
              siteConfig={siteConfig}
              onParticipantClick={goToParticipant}
            />
          ),
          inRange: (
            <DashboardCardView
              participants={groupedParticipants.inRange}
              siteConfig={siteConfig}
              onParticipantClick={goToParticipant}
            />
          ),
          inactive: (
            <DashboardCardView
              participants={groupedParticipants.inactive}
              siteConfig={siteConfig}
              onParticipantClick={goToParticipant}
            />
          ),
        }
      case DashboardView.GRAPH_VIEW:
        return {
          priority: (
            <DashboardGraphView
              participants={groupedParticipants.priority}
              siteConfig={siteConfig}
              onParticipantClick={goToParticipant}
            />
          ),
          inRange: (
            <DashboardGraphView
              participants={groupedParticipants.inRange}
              siteConfig={siteConfig}
              onParticipantClick={goToParticipant}
            />
          ),
          inactive: (
            <DashboardGraphView
              participants={groupedParticipants.inactive}
              siteConfig={siteConfig}
              onParticipantClick={goToParticipant}
            />
          ),
        }
      default:
        return undefined
    }
  }

  const view = selectView(userConfig.dashboardView)

  if (!view) {
    return <Spinner />
  }

  return (
    <React.Fragment>
      <DashboardViewToggle></DashboardViewToggle>
      <Grid container>
        <Grid item xs={12} style={{ marginTop: 10 }}>
          <Grouping group="Priority">{view.priority}</Grouping>
          <br></br>
          <Grouping
            group="Active"
            sortBy={sortBy}
            setCustomSort={setCustomSort}
          >
            {view.inRange}
          </Grouping>
          <br></br>
          <Grouping group="Inactive">{view.inactive}</Grouping>
        </Grid>
      </Grid>
      <DischargeDialog />
    </React.Fragment>
  )
}

export default Dashboard
