import {
  alarmOn,
  alarmOff,
} from '../actions'
import { useGlobalState } from './useGlobalState'

export default () => {
  const { dispatch } = useGlobalState()
  const setAlarmOn = (id: string) => {
    dispatch(alarmOn(id))
  }
  const setAlarmOff = (id: string) => {
    dispatch(alarmOff(id))
  }

  return {
    setAlarmOn,
    setAlarmOff
  }
}