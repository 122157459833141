import { useCallback } from "react"
import { useKeycloak } from "@providers/auth"
import useToast from "@providers/data/hooks/useToast"
import { addParticipant } from "@lib/fetch-requests"
import { REACT_APP_DEXCOM_API_URL, REACT_APP_DEXCOM_API_CLIENT_ID } from "@config/env"
import { INewParticipant } from "@models/form.models"

export default () => {
  const { token } = useKeycloak()
  const { createToast } = useToast()

  const createParticipant = useCallback((data: INewParticipant) =>
    addParticipant(token, data)
      .then((participant) => {
        const redirectUri = `${window.location.protocol}//${window.location.host}/participant/add/callback`
        const state = participant.id
        const url = [
          REACT_APP_DEXCOM_API_URL,
          '/v2/oauth2/login',
          '?',
          `client_id=${REACT_APP_DEXCOM_API_CLIENT_ID}`,
          '&',
          `redirect_uri=${redirectUri}`,
          '&',
          'response_type=code',
          '&',
          'scope=offline_access',
          '&',
          `state=${state}`,
        ].join('')
        // off we go!
        window.location.replace(url)
      })
      .catch(e => {
        console.log(e)
        createToast('Error - Could not create participant', true)
      }),
    [token, createToast]
  )

  return createParticipant
}
