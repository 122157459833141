import { REACT_APP_REST_SERVICE_URL, REACT_APP_DEXCOM_API_URL } from './env'

export const alarmUrl = `${REACT_APP_REST_SERVICE_URL}/api/alarm`
export const participantUrl = `${REACT_APP_REST_SERVICE_URL}/api/participant`
export const allParticipantsUrl = `${REACT_APP_REST_SERVICE_URL}/api/allParticipants`
export const exportParticipantsUrl = `${REACT_APP_REST_SERVICE_URL}/api/export`
export const siteUrl = `${REACT_APP_REST_SERVICE_URL}/maintenance/site`
export const sitesUrl = `${REACT_APP_REST_SERVICE_URL}/maintenance/sites`
export const getSandboxPatientsUrl = `${REACT_APP_DEXCOM_API_URL}/patients`
export const addSandboxParticipantUrl = `${REACT_APP_REST_SERVICE_URL}/maintenance/addSandboxParticipant`
export const activateSandboxParticipantUrl = `${REACT_APP_REST_SERVICE_URL}/maintenance/activateSandboxParticipant`
export const inactivateSandboxParticipantUrl = `${REACT_APP_REST_SERVICE_URL}/maintenance/inactivateSandboxParticipant`
export const userUrl = `${REACT_APP_REST_SERVICE_URL}/api/user`
export const usersUrl = `${REACT_APP_REST_SERVICE_URL}/api/users`
export const configUrl = `${REACT_APP_REST_SERVICE_URL}/api/config`
export const resetPasswordUrl = `${REACT_APP_REST_SERVICE_URL}/api/resetPassword`
