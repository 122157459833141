import { ActionTypes, Action } from '../actions'

export interface ToastState {
  message: string
  error: boolean
  show: boolean
}

export default (state: ToastState, action: Action): ToastState => {
  switch (action.type) {
    case ActionTypes.SET_TOAST:
      return { message: action.payload, error: false, show: true }
    case ActionTypes.SET_ERROR_TOAST:
      return { message: action.payload, error: true, show: true }
    case ActionTypes.HIDE_TOAST:
      return { ...state, show: false }
    default:
      return state
  }
}
