import React, { useEffect, useState } from 'react'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogActions from '@material-ui/core/DialogActions'
import CloseIcon from '@material-ui/icons/Close'
import {
  Button,
  TextField,
  Dialog,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  MenuItem,
  Theme,
  Typography,
  useTheme,
  Divider,
} from '@material-ui/core'
import { generatePassword } from '@lib/utils/password'
import { enumKeys } from '@lib/utils/enumKeys'
import { ROLE_OPTION } from '@models/index'
import useDashboardStyles from '@components/GlobalStyles'

interface ICreateUserModalProps {
  open: boolean
  addDashboardUser: (username: string, password: string, role: string) => void
  handleClose: () => void
}

export default function CreateUserModalView({
  open,
  addDashboardUser,
  handleClose,
}: ICreateUserModalProps) {
  const theme = useTheme()
  const classes = useDashboardStyles(theme)

  // create state variables for each input
  const [userName, setUserName] = useState('')
  const [password, setPassword] = useState('')
  const [role, setRole] = useState('')

  useEffect(() => {
    setUserName('')
    setPassword(generatePassword())
    setRole('')
  }, [open])

  const handleSubmit = (event: React.MouseEvent) => {
    event.preventDefault()
    addDashboardUser(userName, password, role)
  }

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <MuiDialogTitle>
          <Typography style={{ color: theme.palette.text.secondary }}>
            Add New User
          </Typography>
          <IconButton
            className={classes.closeButton}
            aria-label="close"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </MuiDialogTitle>
        <Divider />
        <Grid container justify="space-around" alignItems={'center'}>
          <Grid item xs={9} style={{ marginTop: 10 }}>
            <TextField
              label="User Name"
              variant="outlined"
              required
              value={userName}
              className={classes.userTextField}
              onChange={(e) => setUserName(e.target.value)}
            />
          </Grid>
          <Grid item xs={9} style={{ marginTop: 10 }}>
            <TextField
              label="Password"
              variant="outlined"
              required
              value={password}
              title="User will reset their password on first login"
              className={classes.userTextField}
            />
          </Grid>
          <Grid item xs={9} style={{ marginTop: 10 }}>
            <TextField
              label="Role"
              select
              required
              value={role}
              onChange={(e) => setRole(e.target.value)}
              helperText="Please select a role"
              className={classes.userTextField}
            >
              {enumKeys(ROLE_OPTION).map((key) => (
                <MenuItem key={key} value={ROLE_OPTION[key]}>
                  {ROLE_OPTION[key]}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>

        <MuiDialogActions>
          <Button onClick={handleClose} color="default" variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            autoFocus
          >
            Create User
          </Button>
        </MuiDialogActions>
      </Dialog>
    </React.Fragment>
  )
}
