import { useContext, useEffect } from 'react'
import { useKeycloak } from '@providers/auth'
import {
  addDashboardUser,
  getDashboardUsers,
} from '@lib/fetch-requests'
import { IDashboardUserDetails } from '@models/index'

import { allUsersAction, newUserAction } from '../actions'
import { DataContext } from '../data.provider'
import useToast from './useToast'

interface UsersData {
  users: IDashboardUserDetails[]
  newUser: (username: string, password: string, role: string) => void
}

const useUsers = (): UsersData => {
  const ctx = useContext(DataContext)

  if (!ctx) {
    throw new Error('useUsers hook must be used inside DataProvider context')
  }

  const { state, dispatch } = ctx

  const { token, parsed } = useKeycloak()
  const { createToast } = useToast()

  useEffect(() => {
    getDashboardUsers(token)
      .then((allUsers) => {
        allUsers = allUsers.map((user) => {
          if (user.id === parsed.sub){
            return {
              ...user,
              isCurrentUser: true
            }
          }
          return user
        })
        dispatch(allUsersAction(allUsers))
      })
      .catch((e) => {
        console.log(e)
        createToast('Failed To Load Users', true)
      })
      // eslint-disable-next-line
  }, [])

  const newUser = (username: string, password: string, role: string) =>
    addDashboardUser(token, username, password, role)
      .then((user) => {
        createToast('User Created')
        dispatch(newUserAction(user))
      })
      .catch((e) => {
        console.log(e)
        createToast('User Create Failed', true)
      })

  return {
    users: state.users,
    newUser,
  }
}

export default useUsers
