import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { useTheme } from '@material-ui/core/styles'
import { useKeycloak } from '@providers/auth'
import useResetPassword from '@providers/data/hooks/useResetPassword'
import ResetPasswordModalView from '@components/User/ResetPasswordModalView'

export default function ContextMenu() {
  const theme = useTheme()
  const { keycloak, logout, token, parsed } = useKeycloak()
  const password = useResetPassword(token, logout)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const doLogout = () => {
    handleClose()
    logout()
  }

  const handleResetPassword = () => {
    password.handleResetPassword(parsed.sub as string)
  }

  const parsedObject = keycloak.tokenParsed ?? null
  let userName = 'unknown'
  if (parsedObject) {
    const parsedKeys = Object.keys(parsedObject)
    const parsedValues = Object.values(parsedObject)

    const indx = parsedKeys.indexOf('preferred_username') //Its not in the kyecloak provided interface for some reason.
    userName = parsedValues[indx]
  }

  return (
    <div>
      <IconButton onClick={handleClick} title={userName}>
        <AccountCircleIcon
          fontSize="large"
          style={{ color: theme.oppositePalette.colorInverted }}
        />
      </IconButton>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose} component={Link} to="/faq">
          FAQ
        </MenuItem>
        <MenuItem onClick={handleResetPassword}>Reset Password</MenuItem>
        <MenuItem onClick={doLogout}>Logout</MenuItem>
      </Menu>
      <ResetPasswordModalView
        open={password.open}
        password={password.newPassword}
        onResetPassword={password.submitResetPassword}
        onClose={password.closeResetPasswordModal}
      ></ResetPasswordModalView>
    </div>
  )
}
