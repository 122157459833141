import React, {
  createContext,
  FunctionComponent,
  useEffect,
  useReducer,
} from 'react'
import {
  DashboardView,
  IAcknowledgement,
  IDashboardUserDetails,
  ISiteDetails,
} from '@models/index'
import { CUSTOM_SORT_OPTIONS } from '@models/sortOptions.models'
import configReducer, { ConfigState } from './reducers/config.reducer'
import toastReducer, { ToastState } from './reducers/toast.reducer'
import { Action, tickAction } from './actions'
import participantDataReducer, {
  ParticipantDataState,
} from './reducers/participantData.reducer'
import customSortReducer, { SortByState } from './reducers/customSort.reducer'
import dischargeReducer, { DischargeState } from './reducers/discharge.reducer'
import acknowledgementReducer from './reducers/acknowledgement.reducer'
import siteReducer from './reducers/site.reducer'
import userReducer from './reducers/user.reducer'

type InitialStateType = {
  participantData: ParticipantDataState
  config: ConfigState
  toast: ToastState
  sortBy: SortByState
  dischargeState: DischargeState
  acknowledgements: IAcknowledgement[]
  sites: ISiteDetails[]
  users: IDashboardUserDetails[]
}

const initialState: InitialStateType = {
  participantData: {},
  acknowledgements: [],
  config: {
    siteConfig: undefined,
    userConfig: { dashboardView: DashboardView.LIST_VIEW },
  },
  toast: {
    message: '',
    error: false,
    show: false,
  },
  sortBy: CUSTOM_SORT_OPTIONS.TIME_OUT_OF_RANGE,
  dischargeState: {
    id: '',
    showDialog: false,
  },
  sites: [],
  users: [],
}

const reducer = (
  {
    participantData,
    config,
    toast,
    sortBy,
    dischargeState,
    acknowledgements,
    sites,
    users,
  }: InitialStateType,
  action: Action
) => {
  return {
    participantData: participantDataReducer(
      participantData,
      config.siteConfig,
      acknowledgements,
      action
    ),
    config: configReducer(config, action),
    toast: toastReducer(toast, action),
    sortBy: customSortReducer(sortBy, action),
    dischargeState: dischargeReducer(dischargeState, action),
    acknowledgements: acknowledgementReducer(acknowledgements, action),
    sites: siteReducer(sites, action),
    users: userReducer(users, action),
  }
}

const DataProvider: FunctionComponent = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    const interval = setInterval(() => dispatch(tickAction(Date.now())), 60000)
    return () => clearInterval(interval)
  }, [])

  return (
    <DataContext.Provider value={{ state, dispatch }}>
      {children}
    </DataContext.Provider>
  )
}

const DataContext = createContext<{
  state: InitialStateType
  dispatch: React.Dispatch<Action>
}>({
  state: initialState,
  dispatch: () => null,
})

export { DataContext, DataProvider }
