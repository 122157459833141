import { get, post } from '@lib/utils/http'
import { AddParticipantResponse, IParticipantRecord } from '@models/index'
import { INewParticipant } from '@models/form.models'
import {
  activateSandboxParticipantUrl,
  addSandboxParticipantUrl,
  getSandboxPatientsUrl,
  inactivateSandboxParticipantUrl
} from '@config/resourceUrls'

type SandboxPatient = {
  code: string,
  id: string,
  name: string,
}

async function inactivateSandboxParticipant(
  token: string,
  siteId: string,
  participantId: string,
): Promise<IParticipantRecord> {
  return post<IParticipantRecord>(
    `${inactivateSandboxParticipantUrl}/${participantId}`,
    { siteId: siteId },
    token
  )
    .then(res => {
      if (!res.parsedBody) {
        throw new Error('Expected parsedBody but got undefined')
      }

      return res.parsedBody
    })
}

async function activateSandboxParticipant(
  token: string,
  siteId: string,
  participantId: string,
  patientCode: string,
): Promise<IParticipantRecord> {
  return post<IParticipantRecord>(
    `${activateSandboxParticipantUrl}/${participantId}`,
    { code: patientCode, siteId: siteId },
    token
  )
    .then(res => {
      if (!res.parsedBody) {
        throw new Error('Expected body but got undefined')
      }

      return res.parsedBody
    })
}

async function addSandboxParticipant(
  token: string,
  siteId: string,
  participant: INewParticipant
): Promise<AddParticipantResponse> {
  return post<{ id: string }>(addSandboxParticipantUrl, { siteId: siteId, ...participant }, token)
    .then(res => {
      if (!res.parsedBody) {
        throw new Error('Expected response body but got undefined')
      }
      return res.parsedBody
    })
}

async function fetchSandboxPatients(token: string): Promise<SandboxPatient[]> {
  return get<SandboxPatient[]>(getSandboxPatientsUrl, token)
    .then(res => {
      if (!res.parsedBody) {
        throw new Error('Expected response body but got undefined')
      }
      return res.parsedBody
    })
}

export async function initializeSandbox(siteId: string, token: string): Promise<void> {
  // 1. Fetch sandbox patients list to get codes
  // 2. For each code, call /createParticipant
  //                   call /activate with code
  const patients = await fetchSandboxPatients(token)
  const inactiveParticipants = (patients.length / 5) | 0
  for (let i = 0; i < patients.length; i++) {
    const patient = patients[i]
    const participant = await addSandboxParticipant(
      token,
      siteId,
      { metas: [patient.name, `00_${i}`, i.toString()], condition: 'T1D' }
    )
    await activateSandboxParticipant(token, siteId, participant.id, patient.code)

    // Inactivate a sample of created participants, doesn't matter which ones...
    if (i < inactiveParticipants) {
      await inactivateSandboxParticipant(token, siteId, participant.id)
    }
  }

  return
}
