import { ISiteConfig } from '@models/index'
import { useGlobalState } from './useGlobalState';

interface SiteConfigData {
  siteConfig?: ISiteConfig
}

// TODO: We can hook this into a context when we're fetching user configuration
const useSiteConfig = (): SiteConfigData => {
  const { state } = useGlobalState()

  return {
    siteConfig: state.config.siteConfig,
  }
};

export default useSiteConfig;
