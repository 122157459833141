import React, { SyntheticEvent, useMemo } from 'react'
import {
  Button,
  Grid,
  makeStyles,
  Typography,
  useTheme,
} from '@material-ui/core'
import NotificationsIcon from '@material-ui/icons/Notifications'
import {
  DataGrid,
  GridCellParams,
  GridColumns,
  GridRowParams,
  GridValueGetterParams,
  MuiEvent,
} from '@material-ui/data-grid'
import { IParticipantData, ISiteConfig } from '@models/index'
import { EGV_STATUS, egvStatusStyle } from '@lib/egv'
import ParticipantActionMenu from '@components/Participant/ParticipantActionMenu'
import TrendArrow from '@components/TrendArrow/TrendArrow'

const useStyles = makeStyles({
  '@global': {
    '@keyframes blinker': {
      '50%': {
        opacity: 0,
      },
    },
  },
  animated: {
    animation: '$blinker 1000ms linear infinite',
  },
})

interface DashboardListViewProps {
  siteConfig: ISiteConfig
  participants: Array<IParticipantData>
  onParticipantClick: (id: string) => void
}

export const DashboardListView: React.FunctionComponent<
  DashboardListViewProps
> = ({ siteConfig, participants, onParticipantClick }) => {
  const theme = useTheme()
  const classes = useStyles(theme)

  const columnsDefinition = useMemo(() => {
    const columns: GridColumns = [
      {
        field: 'status',
        headerName: 'Status',
        width: 175,
        valueGetter: (params: GridValueGetterParams) => {
          const alarm = params.getValue(params.id, 'alarm') || false
          const trigger = params.getValue(params.id, 'alarmTrigger') || false
          const status = params.value || ''
          return { alarm, status, trigger }
        },
        renderCell: (params: GridCellParams) => {
          const { alarm, status, trigger } = params.value as {
            alarm: boolean
            status: string
            trigger: boolean
          }
          return (
            <Button
              className={trigger ? classes.animated : ''}
              style={{
                ...egvStatusStyle(status as EGV_STATUS, theme),
                width: 170,
                height: 25,
                borderRadius: 12,
                textTransform: 'initial',
                fontWeight: 'bold',
              }}
            >
              {alarm && (
                <NotificationsIcon
                  style={{ position: 'absolute', left: '5px' }}
                />
              )}{' '}
              {status}
            </Button>
          )
        },
      },
    ]
    columns.push({
      field: 'latestEgv',
      headerName: 'EGV',
      renderCell: (params: GridCellParams) => {
        const egvValue = params.value + ' mg/dL'
        const trend = params.getValue(params.id, 'trend') as string
        return (
          <Grid container justifyContent="center">
            <Grid item xs={10}>
              <Typography variant="body2">{egvValue}</Typography>
            </Grid>
            <Grid item xs={2} container alignContent="center">
              <TrendArrow trend={trend} />
            </Grid>
          </Grid>
        )
      },
      width: 120,
    })
    siteConfig.metas.forEach((meta, i) => {
      columns.push({
        field: meta.displayname,
        width: i === 0 ? 225 : 160,
        headerName: meta.displayname,
        valueGetter: (params: GridValueGetterParams) => {
          const metadata = params.getValue(params.id, 'metadata') as string[]
          if (!metadata) return ''
          return metadata[i]
        },
      })
    })
    columns.push({
      field: 'condition',
      headerName: 'Condition',
      width: 120,
    })
    columns.push({
      field: 'sessionEnd',
      headerName: 'Session Ends',
      width: 180,
    })
    columns.push({ field: 'received', headerName: 'Received', width: 120 })
    columns.push({
      field: 'id',
      headerName: 'Actions',
      sortable: false,
      filterable: false,
      hideSortIcons: true,
      valueGetter: (params: GridValueGetterParams) => {
        const actions = params.getValue(params.id, 'actions') as string[]
        if (!actions) return []
        return actions
      },
      renderCell: (params: GridCellParams) => (
        <ParticipantActionMenu
          id={params.id as string}
          menuOptions={params.value as string[]}
        />
      ),
    })

    return columns
  }, [siteConfig, classes.animated, theme])

  const handleParticipantClick = (
    params: GridRowParams,
    _event: MuiEvent<SyntheticEvent<Element, Event>>
  ) => {
    onParticipantClick(params.id as string)
  }

  if (participants.length === 0) {
    return <div>No Patients</div>
  }

  return (
    <DataGrid
      disableColumnMenu
      rows={participants}
      columns={columnsDefinition}
      hideFooter
      density="compact"
      autoHeight
      onRowClick={handleParticipantClick}
    />
  )
}
