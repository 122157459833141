import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Dashboard from '@components/Dashboard/Dashboard'
import Settings from '@components/Settings/Settings'
import FAQ from '@components/FAQ/FAQ'
import ExportData from '@components/ExportData/ExportData'
import ActivateParticipant from '@components/ParticipantAdd/ActivateParticipant'
import Participant from '@components/Participant/Participant'
import NotFoundPage from '@components/NotFound/NotFound'
import UsersHome from '@components/User/UsersHome'
import SitesHome from '@components/Site/SitesHome'
import { useKeycloak } from '@providers/auth'

import ProtectedRoute from './ProtectedRoute/ProtectedRoute'

export default () => {
  const { keycloak } = useKeycloak()

  return (
    <Switch>
      <Route exact path="/" component={Dashboard} />
      <Route exact path="/exportdata" component={ExportData} />
      <Route path="/participant/add/callback" component={ActivateParticipant} />
      <Route path="/participant/:id" component={Participant} />
      <ProtectedRoute
        isAuthenticated={keycloak.hasRealmRole('dashboard-admin')}
        authenticationPath={'/404'}
        path="/settings"
        component={Settings}
      />
      <ProtectedRoute
        isAuthenticated={keycloak.hasRealmRole('dashboard-admin')}
        authenticationPath={'/404'}
        path="/users"
        component={UsersHome}
      />
      <ProtectedRoute
        isAuthenticated={keycloak.hasRealmRole('dashboard-engineer')}
        authenticationPath={'/404'}
        path="/sites"
        component={SitesHome}
      />
      <Route path="/faq" component={FAQ} />
      <Route path="/404" component={NotFoundPage} />
      <Redirect to="/404" />
    </Switch>
  )
}
