import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import PhoneIcon from '@material-ui/icons/Phone'
import ComputerIcon from '@material-ui/icons/Computer'
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications'
import GroupIcon from '@material-ui/icons/Group'
import AssessmentIcon from '@material-ui/icons/Assessment'
import LocalHospitalIcon from '@material-ui/icons/LocalHospital'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { useTheme } from '@material-ui/core/styles'
import { Dialog } from '@material-ui/core'

import { useKeycloak } from '@providers/auth'
import ContextMenu from '@components/ContextMenu/ContextMenu'
import Logo from '@components/Logo/Logo'
import useAddParticipant from '@components/ParticipantAdd/useAddParticipant'
import AddParticipantForm from '@components/ParticipantAdd/AddParticipantForm'
import { INewParticipant } from '@models/form.models'
import useSiteConfig from '@providers/data/hooks/useSiteConfig'

function Header(): JSX.Element {
  const theme = useTheme()
  const { keycloak } = useKeycloak()
  const { siteConfig } = useSiteConfig()
  const createParticipant = useAddParticipant()
  const [addParticipantFormOpen, setAddParticipantFormOpen] = useState(false)

  const addParticipant = () => {
    setAddParticipantFormOpen(true)
  }

  const cancelAddParticipant = () => {
    setAddParticipantFormOpen(false)
  }

  const submitParticipant = (data: INewParticipant) => {
    createParticipant(data)
  }

  return (
    <React.Fragment>
      <Container maxWidth="xl">
        <Grid container justify="flex-end" alignItems="center">
          <PhoneIcon style={{ fontSize: 14 }} />
          <Typography color="textSecondary" style={{ fontSize: 14 }}>
            Call Technical Support at 1-{siteConfig?.supportphone.substr(0, 3)}-
            {siteConfig?.supportphone.substr(3, 3)}-
            {siteConfig?.supportphone.substr(6, 4)}
          </Typography>
        </Grid>
      </Container>
      <Paper
        elevation={3}
        style={{
          marginBottom: 10,
          width: '100%',
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <Container maxWidth="xl">
          <Grid container alignItems="center" justify="space-between">
            <Logo />
            <ContextMenu />
          </Grid>
          <Grid container alignItems="center" justify="space-between">
            <Toolbar
              disableGutters
              style={{
                color: theme.oppositePalette.colorInverted,
              }}
            >
              <Button
                key="dash123"
                to="/"
                exact
                component={NavLink}
                activeStyle={{ color: theme.palette.primary.main }}
                color="inherit"
              >
                <ComputerIcon></ComputerIcon>
                Dashboard
              </Button>
              {keycloak.hasRealmRole('dashboard-admin') && (
                <Button
                  key="settings123"
                  to="/settings"
                  component={NavLink}
                  activeStyle={{ color: theme.palette.primary.main }}
                  color="inherit"
                >
                  <SettingsApplicationsIcon></SettingsApplicationsIcon>
                  Settings
                </Button>
              )}
              <Button
                key="data123"
                to="/exportdata"
                component={NavLink}
                activeStyle={{ color: theme.palette.primary.main }}
                color="inherit"
              >
                <AssessmentIcon></AssessmentIcon>
                Export
              </Button>
              {keycloak.hasRealmRole('dashboard-admin') && (
                <Button
                  key="usersListView"
                  to="/users"
                  component={NavLink}
                  activeStyle={{ color: theme.palette.primary.main }}
                  color="inherit"
                >
                  <GroupIcon></GroupIcon>
                  Users
                </Button>
              )}
              {keycloak.hasRealmRole('dashboard-engineer') && (
                <Button
                  key="sitesListView"
                  to="/sites"
                  component={NavLink}
                  activeStyle={{ color: theme.palette.primary.main }}
                  color="inherit"
                >
                  <LocalHospitalIcon></LocalHospitalIcon>
                  Sites
                </Button>
              )}
            </Toolbar>
            <Button
              onClick={addParticipant}
              color="primary"
              variant="contained"
              style={{ fontSize: 11, textTransform: 'initial' }}
            >
              Add Participant
            </Button>
          </Grid>
        </Container>
      </Paper>
      <Dialog
        open={addParticipantFormOpen}
        onClose={cancelAddParticipant}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <AddParticipantForm
          metadata={siteConfig ? siteConfig.metas : []}
          onCancel={cancelAddParticipant}
          onSubmit={submitParticipant}
        />
      </Dialog>
    </React.Fragment>
  )
}

export default Header
