import {
  green,
  grey,
  lightGreen,
  orange,
  red,
  yellow,
} from '@material-ui/core/colors'
import { createTheme } from '@material-ui/core/styles'

export const darkTheme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#58A618', // Dexcom Green
    },
    secondary: {
      main: '#0075C2', // Dexcom Blue
    },
    warning: {
      main: orange[600],
    },
    info: {
      main: yellow[500],
    },
  },
  oppositePalette: {
    backgroundColor: grey[900],
    colorInverted: grey[300],
    color: grey[100],
  },
  egvAlert: {
    backgroundColor: red[500],
    borderColor: red[500],
    color: 'inherit',
  },
  egvWarn: {
    backgroundColor: yellow[900],
    borderColor: yellow[900],
    color: 'inherit',
  },
  egvNormal: {
    backgroundColor: '#58A618', // Dexcom Green,
    borderColor: '#58A618', // Dexcom Green,
    color: grey[100],
  },
  egvInactive: {
    backgroundColor: grey[200],
    borderColor: grey[200],
    color: grey[700],
  },
  inRange: {
    backgroundColor: green[700],
    borderColor: green[700],
    color: lightGreen[50],
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 750,
      md: 900,
      lg: 1110,
      xl: 1720,
    },
  },
  typography: {
    button: {
      fontSize: 11,
      textTransform: 'initial',
    },
    h5: {
      fontSize: '1rem',
    },
  },
})
