import * as Actions from "../actions";
import { ToastState } from "../reducers/toast.reducer";
import { useGlobalState } from "./useGlobalState";

interface ToastData {
  toast: ToastState
  createToast: (message: string, error?: boolean) => void
  hideToast: () => void
}

const useToast = (): ToastData => {
  const { state, dispatch } = useGlobalState()

  const createToast = (message: string, error?: boolean) => {
    if (error) {
      dispatch(Actions.setErrorToast(message))
    } else {
      dispatch(Actions.setToast(message))
    }
  }

  const hideToast = () => {
    dispatch(Actions.hideToast())
  }

  return {
    toast: state.toast,
    createToast,
    hideToast,
  }

}

export default useToast
