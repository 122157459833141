import { useCallback } from "react"
import { useKeycloak } from "@providers/auth"
import { ISiteConfig } from "@models/index"
import {
  inactivateParticipant,
  reactivateParticipant,
  acknowledgeAlarm,
  dischargeParticipant,
  submitComment,
  saveSiteConfig,
  updateMetadata
} from "@lib/fetch-requests"

import useDataActions from "./useDataActions"
import { useParticipantMap } from "./useGlobalState"
import useToast from './useToast'

export default () => {
  const { token } = useKeycloak()
  const participants = useParticipantMap()
  const { createToast } = useToast()
  const { collectNewAcknowledgement } = useDataActions()

  const inactivate = useCallback((participantId: string) => {
    inactivateParticipant(token, participantId)
  }, [token])

  const reactivate = useCallback((participantId: string) => {
    reactivateParticipant(token, participantId)
  }, [token])

  const discharge = useCallback((participantId: string) => {
    dischargeParticipant(token, participantId)
  }, [token])

  const acknowledge = useCallback((participantId: string) => {
    const participant = participants[participantId]
    if (participant && participant.alarms.length > 0) {
      const alarm = participant.alarms[0]
      acknowledgeAlarm(token, participantId, alarm.id).then(ack => {
        collectNewAcknowledgement(ack)
      })
    }
  }, [token, participants, collectNewAcknowledgement])

  const updateSiteConfig = useCallback((siteConfig: ISiteConfig) => {
    return saveSiteConfig(token, siteConfig)
  }, [token])

  const addComment = useCallback(
    (participantId: string, comment: string) => submitComment(participantId, comment, token)
      .then(() => {
        createToast('Successfully added comment')
      }).catch(e => {
        console.log(e)
        createToast('Error - Could not add comment', true)
      }),
    [token, createToast]
  )

  const updateMetadataValue = useCallback(
    (participantId: string, value: string) => updateMetadata(participantId, value, token)
      .catch(e => {
        console.log(e)
        createToast('Error - Could not update metadata', true)
      }),
    [token, createToast]
  )

  return { inactivate, reactivate, acknowledge, discharge, addComment, updateSiteConfig, updateMetadataValue }
}
