import React from 'react'
import { Grid, Paper } from '@material-ui/core'
import ParticipantDetails from '@components/Participant/ParticipantDetails'
import { IParticipantData, ISiteConfig } from '@models/index'
import ClickableParticipant from './ClickableParticipant'

interface CardViewProps {
  participants: IParticipantData[]
  siteConfig: ISiteConfig
  onParticipantClick: (pid: string) => void
}

const DashboardCardView: React.FunctionComponent<CardViewProps> = ({
  participants,
  siteConfig,
  onParticipantClick,
}) => {
  if (participants.length === 0) {
    return <div>No Patients</div>
  }
  return (
    <Grid container spacing={2}>
      {participants.map((participant) => {
        return (
          <Grid key={participant.id} item xs={12} sm={6} lg={4} xl={3}>
            <Paper>
              <ClickableParticipant
                pid={participant.id}
                onClick={onParticipantClick}
              >
                <ParticipantDetails
                  participant={participant}
                  siteConfig={siteConfig}
                />
              </ClickableParticipant>
            </Paper>
          </Grid>
        )
      })}
    </Grid>
  )
}

export default DashboardCardView
