import { ISiteDetails } from '@models/index'
import { Action, ActionTypes } from '../actions'

export default (state: ISiteDetails[], action: Action): ISiteDetails[] => {
  switch (action.type) {
    case ActionTypes.ALL_SITES: {
      return [...action.payload]
    }
    case ActionTypes.NEW_SITE: {
      return [...state, action.payload]
    }
    case ActionTypes.DELETE_SITE: {
      const newState = [...state]
      const idx = newState.findIndex(site => site.siteId === action.payload)
      if (idx > -1) {
        newState.splice(idx, 1)
      }
      return newState
    }
    default:
      return state
  }
}
