import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import { ISiteConfig } from '@models/index'

type AlarmSettingsState = {
  lowaudiblealarmsenabled: boolean
  highaudiblealarmsenabled: boolean
  signallossaudiblealarmsenabled: boolean
}

type AlarmSettingsProps = {
  lowaudiblealarmsenabled: boolean
  highaudiblealarmsenabled: boolean
  signallossaudiblealarmsenabled: boolean
  onsave: (settings: ISiteConfig) => void
}

const useStyles = makeStyles({
  fiftyFive: {
    marginLeft: 55,
  },
  fifty: {
    marginLeft: 50,
  },
})

export default function AlarmSettings(props: AlarmSettingsProps) {
  const classes = useStyles()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onsave({
      ...props,
      [event.target.name]: event.target.checked,
    } as AlarmSettingsState as ISiteConfig)
  }

  return (
    <Card>
      <CardHeader title="Audible Alerts" />
      <Divider />
      <List>
        <ListItem>
          <FormControlLabel
            control={
              <Switch
                name="lowaudiblealarmsenabled"
                onChange={handleChange}
                checked={props.lowaudiblealarmsenabled}
                classes={{ root: classes.fiftyFive }}
                color="primary"
              />
            }
            label="Audible Low Alerts"
            labelPlacement="start"
          />
        </ListItem>
        <ListItem>
          <FormControlLabel
            control={
              <Switch
                name="highaudiblealarmsenabled"
                onChange={handleChange}
                checked={props.highaudiblealarmsenabled}
                classes={{ root: classes.fifty }}
                color="primary"
              />
            }
            label="Audible High Alerts"
            labelPlacement="start"
          />
        </ListItem>
        <ListItem>
          <FormControlLabel
            control={
              <Switch
                name="signallossaudiblealarmsenabled"
                onChange={handleChange}
                checked={props.signallossaudiblealarmsenabled}
                color="primary"
              />
            }
            label="Audible Check Sensor Alerts"
            labelPlacement="start"
          />
        </ListItem>
      </List>
    </Card>
  )
}
