import {
  green,
  grey,
  lightGreen,
  orange,
  red,
  yellow,
} from '@material-ui/core/colors'
import { createTheme } from '@material-ui/core/styles'

export const lightTheme = createTheme({
  palette: {
    type: 'light',
    background: {
      default: grey[50],
      paper: '#fff', //white
    },
    primary: {
      main: '#58A618', // Dexcom Green
    },
    secondary: {
      main: '#0075C2', // Dexcom Blue
    },
    warning: {
      main: orange[600],
    },
    info: {
      main: yellow[500],
    },
  },
  oppositePalette: {
    backgroundColor: grey[800],
    colorInverted: grey[700],
    color: grey[300],
  },
  egvAlert: {
    backgroundColor: red[50],
    borderColor: grey[300],
    color: red[500],
  },
  egvWarn: {
    backgroundColor: yellow[50],
    borderColor: grey[300],
    color: grey[700],
  },
  egvNormal: {
    backgroundColor: lightGreen[100],
    borderColor: grey[300],
    color: grey[700],
  },
  egvInactive: {
    backgroundColor: grey[50],
    borderColor: grey[300],
    color: grey[700],
  },
  inRange: {
    backgroundColor: lightGreen[50],
    borderColor: green[900],
    color: green[900],
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 750,
      md: 900,
      lg: 1110,
      xl: 1720,
    },
  },
  typography: {
    button: {
      fontSize: 11,
      textTransform: 'initial',
    },
    h5: {
      fontSize: '1rem',
    },
  },
})

declare module '@material-ui/core/styles/createTheme' {
  interface Theme {
    oppositePalette: {
      color: React.CSSProperties['color']
      backgroundColor: React.CSSProperties['backgroundColor']
      colorInverted: React.CSSProperties['color']
    }
    egvAlert: {
      color: React.CSSProperties['color']
      backgroundColor: React.CSSProperties['backgroundColor']
      borderColor: React.CSSProperties['borderColor']
    }
    egvNormal: {
      color: React.CSSProperties['color']
      backgroundColor: React.CSSProperties['backgroundColor']
      borderColor: React.CSSProperties['borderColor']
    }
    egvWarn: {
      color: React.CSSProperties['color']
      backgroundColor: React.CSSProperties['backgroundColor']
      borderColor: React.CSSProperties['borderColor']
    }
    egvInactive: {
      color: React.CSSProperties['color']
      backgroundColor: React.CSSProperties['backgroundColor']
      borderColor: React.CSSProperties['borderColor']
    }
    inRange: {
      color: React.CSSProperties['color']
      backgroundColor: React.CSSProperties['backgroundColor']
      borderColor: React.CSSProperties['borderColor']
    }
  }
  interface ThemeOptions {
    oppositePalette: {
      color: React.CSSProperties['color']
      backgroundColor: React.CSSProperties['backgroundColor']
      colorInverted: React.CSSProperties['color']
    }
    egvAlert: {
      color: React.CSSProperties['color']
      backgroundColor: React.CSSProperties['backgroundColor']
      borderColor: React.CSSProperties['borderColor']
    }
    egvNormal: {
      color: React.CSSProperties['color']
      backgroundColor: React.CSSProperties['backgroundColor']
      borderColor: React.CSSProperties['borderColor']
    }
    egvWarn: {
      color: React.CSSProperties['color']
      backgroundColor: React.CSSProperties['backgroundColor']
      borderColor: React.CSSProperties['borderColor']
    }
    egvInactive: {
      color: React.CSSProperties['color']
      backgroundColor: React.CSSProperties['backgroundColor']
      borderColor: React.CSSProperties['borderColor']
    }
    inRange: {
      color: React.CSSProperties['color']
      backgroundColor: React.CSSProperties['backgroundColor']
      borderColor: React.CSSProperties['borderColor']
    }
  }
}
