import { get, post, put, del } from '@lib/utils/http'
import {
  AddParticipantResponse,
  IParticipantRecord,
  IDashboardUserDetails,
  IAcknowledgement,
  ISiteDetails,
  ISiteConfig,
} from '@models/index'
import {
  alarmUrl,
  allParticipantsUrl,
  configUrl,
  exportParticipantsUrl,
  participantUrl,
  resetPasswordUrl,
  sitesUrl,
  siteUrl,
  usersUrl,
  userUrl,
} from '@config/resourceUrls'

export const addParticipant = async (
  token: string,
  body: unknown
): Promise<AddParticipantResponse> => {
  const resp = await post<AddParticipantResponse>(participantUrl, body, token)
  if (!resp.parsedBody) {
    throw new Error('Expected response body, got undefined')
  }
  return resp.parsedBody
}

interface ActivateBody {
  code: string
}

export const activateParticipant = async (
  token: string,
  id: string,
  body: ActivateBody
): Promise<IParticipantRecord> => {
  const resp = await post<IParticipantRecord>(
    `${participantUrl}/${id}/activate`,
    body,
    token
  )
  if (!resp.parsedBody) {
    throw new Error('Expected response body, got undefined')
  }

  return resp.parsedBody
}

export const reactivateParticipant = async (
  token: string,
  participantId: string
): Promise<void> => {
  await put<IParticipantRecord>(
    `${participantUrl}/${participantId}/reactivate`,
    {},
    token
  )
}

export const acknowledgeAlarm = async (
  token: string,
  participantId: string,
  alarmId: string
): Promise<IAcknowledgement> => {
  const resp = await post<IAcknowledgement>(
    `${alarmUrl}/acknowledge`,
    { participantId, alarmId },
    token
  )

  if (!resp.parsedBody) {
    throw new Error('Expected response body, got undefined')
  }
  return resp.parsedBody
}

export const dischargeParticipant = async (
  token: string,
  participantId: string
): Promise<void> => {
  await put<IParticipantRecord>(
    `${participantUrl}/${participantId}/discharge`,
    {},
    token
  )
}

export const addDashboardUser = async (
  token: string,
  username: string,
  password: string,
  role: string
): Promise<IDashboardUserDetails> => {
  const reqBody = {
    username: username,
    password: password,
    role: role,
  }

  const resp = await post<IDashboardUserDetails>(userUrl, reqBody, token)
  if (!resp.parsedBody) {
    throw new Error('Expected response body, got undefined')
  }
  return resp.parsedBody
}

export const getDashboardUsers = async (
  token: string
): Promise<IDashboardUserDetails[]> => {
  const resp = await get<IDashboardUserDetails[]>(usersUrl, token)
  if (!resp.parsedBody) {
    throw new Error('Expected response body, got undefined')
  }
  return resp.parsedBody
}

export const inactivateParticipant = async (
  token: string,
  participantId: string
): Promise<void> => {
  await put<IParticipantRecord>(
    `${participantUrl}/${participantId}/inactivate`,
    {},
    token
  )
}

export const addSite = async (
  token: string,
  siteId: string,
  adminUsername: string,
  password: string
): Promise<ISiteDetails> => {
  const reqBody = {
    adminUsername: adminUsername,
    password: password,
    siteId: siteId,
  }
  const resp = await post<ISiteDetails>(siteUrl, reqBody, token)
  if (!resp.parsedBody) {
    throw new Error('Expected response body, got undefined')
  }
  return resp.parsedBody
}

export const deleteSite = async (token: string, siteId: string): Promise<void> => {
  await del(siteUrl, siteId, token)
}

export const getSites = async (token: string): Promise<ISiteDetails[]> => {
  const resp = await get<ISiteDetails[]>(sitesUrl, token)
  if (!resp.parsedBody) {
    throw new Error('Expected response body, got undefined')
  }
  return resp.parsedBody
}

export const saveSiteConfig = async (token: string, siteConfig: ISiteConfig): Promise<void> => {
  await put<ISiteConfig>(configUrl, siteConfig, token)
}

export const submitComment = async (participantId: string, comment: string, token: string): Promise<void> => {
  await put<void>(`${participantUrl}/${participantId}/comment`, { comment: comment }, token)
}

export const updateMetadata = async (participantId: string, value: string, token: string): Promise<void> => {
  await put<void>(`${participantUrl}/${participantId}/metadata`, { value: value }, token)
}

export const getAllParticipants = async (token: string): Promise<IParticipantRecord[]> => {
  const resp = await get<IParticipantRecord[]>(allParticipantsUrl, token)
  if (!resp.parsedBody) {
    throw new Error('Expected response body, got undefined')
  }
  return resp.parsedBody
}

export const exportParticipants = async (token: string, participantIds: string[]): Promise<string[][]> => {
  const resp = await post<string[][]>(exportParticipantsUrl, participantIds, token)
  if (!resp.parsedBody) {
    throw new Error('Expected response body, got undefined')
  }
  return resp.parsedBody
}

export const resetUserPassword = async (
  userId: string,
  password: string,
  token: string,
): Promise<void> => {
  const reqBody = {
    password: password,
  }
  await put<void>(`${userUrl}/${userId}/resetPassword`, reqBody, token)
}

export const resetPassword = async (
  password: string,
  token: string,
): Promise<void> => {
  const reqBody = {
    password: password,
  }
  await put<void>(resetPasswordUrl, reqBody, token)
}