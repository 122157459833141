import { ISiteConfig, IUserConfig } from "@models/index";
import { Action, ActionTypes } from '../actions'

export interface ConfigState {
  siteConfig?: ISiteConfig
  userConfig: IUserConfig
}

export default (state: ConfigState, action: Action): ConfigState => {
  switch (action.type) {
    case ActionTypes.USER_CONFIG_UPDATE:
      return { ...state, userConfig: action.payload }
    case ActionTypes.SITE_CONFIG_UPDATE:
      return { ...state, siteConfig: action.payload }
    default:
      return state
  }
}
