import { useEffect, useState, useMemo } from "react";
import { exportParticipants, getAllParticipants } from "@lib/fetch-requests";
import useToast from "@providers/data/hooks/useToast";

type ExportParticipantRecord = {
  id: string,
  selected: boolean,
  metas: string[],
  timeInRange: number,
  hypoTime: number,
  hypoEventCount: number,
  hyperTime: number,
  hyperEventCount: number,
  usage: number
}

type ExportData = Record<string, ExportParticipantRecord>

export default function useDataExport(token: string) {
  const [participants, setParticipants] = useState<ExportData>({})
  const { createToast } = useToast()

  const selectedCount = useMemo(() => {
    return Object.values(participants).filter(participant => participant.selected).length
  }, [participants])

  useEffect(() => {
    let mounted = true
    getAllParticipants(token).then((participants) => {
      if (!mounted) {
        return
      }

      const exportParticipants = participants.map((participant) => ({
        id: participant.id,
        selected: false,
        metas: participant.metadata || ['', '', ''],
        timeInRange: participant.withinrangecount * 5,
        hypoTime: participant.belowrangecount * 5,
        hypoEventCount: participant.belowrangecount,
        hyperTime: participant.aboverangecount * 5,
        hyperEventCount: participant.aboverangecount,
        usage: participant.egvcount * 5
      })).reduce((acc, participant) => {
        acc[participant.id] = participant
        return acc
      }, {} as ExportData)
      setParticipants(exportParticipants)
    }).catch(e => {
      console.error(e)
      if (mounted) {
        createToast('Could not fetch participants', true)
      }
    })

    return () => { mounted = false }
    // eslint-disable-next-line
  }, [])

  const toggleSelect = (id: string) => {
    setParticipants(prev => ({
      ...prev,
      [id]: {
        ...prev[id],
        selected: !prev[id].selected
      }
    }))
  }

  const exportSelected = () => {
    createToast('Generating Report')
    const ids = Object.values(participants)
      .filter(participant => participant.selected)
      .map(participant => participant.id)
    exportParticipants(token, ids).then(data => {
      const csvContent = data.map(row => row.join(',')).join('\n')
      const blob = new Blob([csvContent], { type: 'text/csv' })

      // if IE 11
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, `Dashboard Export ${new Date().toISOString()}.csv`)
      } else {
        const encodedURI = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.setAttribute('href', encodedURI)
        link.setAttribute('download', `Dashboard Export ${new Date().toISOString()}.csv`)
        document.body.appendChild(link)
        link.click()
      }
    }).catch(e => {
      console.error(e)
      createToast('Could not export data', true)
    })
  }

  return {
    participants: Object.values(participants),
    selectedCount,
    toggleSelect,
    exportSelected,
  }
}
