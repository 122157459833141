import React from 'react'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import Footer from '@components/Footer/Footer'
import Header from '@components/Header/Header'
import Router from '@components/Router/Router'
import { DataProvider } from '@providers/data/data.provider'
import { BeepProvider } from '@providers/beep/beep.provider'
import WebsocketProvider from '@providers/websocket/Websocket'
import useToast from '@providers/data/hooks/useToast'
import AlarmEngine from '@providers/alarm/AlarmEngine'

const App = () => {
  const { toast, hideToast } = useToast()

  return (
    <div style={{ marginBottom: 150 }}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={toast.show}
        autoHideDuration={3000}
        onClose={hideToast}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={toast.error ? 'error' : 'success'}
        >
          {toast.message}
        </MuiAlert>
      </Snackbar>
      <Header />
      <Container maxWidth="xl">
        <Grid container>
          <Router />
        </Grid>
      </Container>
      <Footer></Footer>
    </div>
  )
}

// Wrap app in Data Provider HOC
export default (): JSX.Element => (
  <DataProvider>
    <WebsocketProvider>
      <BeepProvider>
        <AlarmEngine />
        <App />
      </BeepProvider>
    </WebsocketProvider>
  </DataProvider>
)
