import { useContext, useEffect } from 'react'
import { useKeycloak } from '@providers/auth'
import { addSite, getSites, deleteSite } from '@lib/fetch-requests'
import { ISiteDetails } from '@models/index'
import { initializeSandbox } from '@lib/sandbox'

import { allSitesAction, newSiteAction, deleteSiteAction } from '../actions'
import { DataContext } from '../data.provider'
import useToast from './useToast'

interface SitesData {
  sites: ISiteDetails[]
  newSite: (siteId: string, adminUsername: string, password: string, sandbox?: boolean) => void
  removeSite: (siteId: string) => void
}

const useSites = (): SitesData => {
  const ctx = useContext(DataContext)

  if (!ctx) {
    throw new Error('useSites hook must be used inside DataProvider context')
  }

  const { state, dispatch } = ctx
  const { token } = useKeycloak()
  const { createToast } = useToast()

  useEffect(() => {
    getSites(token)
      .then((allSites) => {
        dispatch(allSitesAction(allSites))
      })
      .catch((e) => {
        console.error(e)
        createToast('Failed To Load Sites', true)
      })
    // eslint-disable-next-line
  }, [])

  const newSite = (siteId: string, adminUsername: string, password: string, sandbox?: boolean) => {
    addSite(token, siteId, adminUsername, password)
      .then((site) => {
        createToast('Site Created')
        dispatch(newSiteAction(site))
        return site
      })
      .then(async (site) => {
        if (sandbox) {
          createToast('Seeding Site Data')
          await initializeSandbox(site.siteId, token)
          createToast('Complete Sandbox Initialization')
        }
        return site
      })
      .catch((e) => {
        console.log(e)
        createToast('Site Create Failed', true)
      })
  }

  const removeSite = (siteId: string) => {
    deleteSite(token, siteId)
      .then(() => {
        dispatch(deleteSiteAction(siteId))
        createToast('Site Deleted')
      })
      .catch(e => {
        console.log(e)
        createToast('Failed to delete site', true)
      })
  }

  return {
    sites: state.sites,
    newSite,
    removeSite
  }
}

export default useSites
