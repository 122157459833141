interface Env {
  REACT_APP_REST_SERVICE_URL: string
  REACT_APP_DEXCOM_API_URL: string
  REACT_APP_DEXCOM_API_CLIENT_ID: string
  REACT_APP_DEXCOM_KEYCLOAK_REALM: string
  REACT_APP_DEXCOM_KEYCLOAK_CLIENT_ID: string
  REACT_APP_DEXCOM_KEYCLOAK_URL: string
  REACT_APP_DASHBOARD_VERSION: string
  REACT_APP_SANDBOX?: string
}

const window_any = window as unknown as Env
export const { REACT_APP_REST_SERVICE_URL } = window_any
export const { REACT_APP_DEXCOM_API_URL } = window_any
export const { REACT_APP_DEXCOM_API_CLIENT_ID } = window_any
export const { REACT_APP_DEXCOM_KEYCLOAK_REALM } = window_any
export const { REACT_APP_DEXCOM_KEYCLOAK_CLIENT_ID } = window_any
export const { REACT_APP_DEXCOM_KEYCLOAK_URL } = window_any
export const { REACT_APP_DASHBOARD_VERSION } = window_any
export const { REACT_APP_SANDBOX } = window_any
