import React, { useMemo, useState } from 'react'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Divider from '@material-ui/core/Divider'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { Button, makeStyles, useTheme } from '@material-ui/core'
import { IComment, IEgvEventRecord } from '@models/index'
import { justDate, justTime } from '@lib/utils/dates'
import { egvStatusStyle } from '@lib/egv'
import { green } from '@material-ui/core/colors'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '40px',
  },
  title: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: theme.palette.text.secondary,
  },
  action: {
    alignSelf: 'center',
    marginTop: 0,
  },
  event: {
    fontWeight: 'bold',
    borderRadius: '20px',
    padding: '10px 20px 10px 20px',
  },
  content: {
    padding: 0,
  },
  cell: {
    paddingTop: 10,
    paddingBottom: 10,
  },
}))

type CommentsCardProps = {
  onAdd: () => void
  comments: IComment[]
  events: IEgvEventRecord[]
}

export default function CommentsCard(props: CommentsCardProps) {
  const theme = useTheme()
  const classes = useStyles(theme)
  const combinedEvents = useMemo(() => {
    const combined = props.comments.map((c) => ({
      type: 'comment',
      text: c.body,
      date: c.date,
    }))
    props.events.forEach((e) => {
      combined.push({
        type: 'event',
        text: e.type,
        date: e.startdate,
      })
    })
    return combined.sort((a, b) => b.date.getTime() - a.date.getTime())
  }, [props.events, props.comments])

  return (
    <Card>
      <CardHeader
        classes={{
          root: classes.root,
          title: classes.title,
          action: classes.action,
        }}
        title="Comments"
        action={
          <Button onClick={props.onAdd} color="primary" variant="contained">
            Add
          </Button>
        }
      />
      <Divider />
      <CardContent
        classes={{ root: classes.content }}
        style={{ maxHeight: 415, overflow: 'auto' }}
      >
        <Table style={{ width: '100%' }}>
          <TableHead classes={{ root: theme.palette.background.paper }}>
            <TableRow>
              <TableCell classes={{ root: classes.cell }} width="100px">
                Date
              </TableCell>
              <TableCell classes={{ root: classes.cell }} width="100px">
                Time
              </TableCell>
              <TableCell classes={{ root: classes.cell }}>Comment</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {combinedEvents.map((comment, i) => (
              <TableRow key={i}>
                <TableCell>{justDate(comment.date)}</TableCell>
                <TableCell>{justTime(comment.date)}</TableCell>
                <TableCell>
                  {comment.type === 'comment' ? (
                    comment.text
                  ) : (
                    <span
                      style={egvStatusStyle(
                        `${comment.text
                          .charAt(0)
                          .toUpperCase()}${comment.text.substr(1)}`,
                        theme
                      )}
                      className={`${classes.event}`}
                    >
                      {' '}
                      {comment.text.charAt(0).toUpperCase() +
                        comment.text.slice(1)}
                    </span>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}
