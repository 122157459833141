import { CSSProperties } from 'react'
import { Theme } from '@material-ui/core'
import { TEN_MINUTES_MILLISECONDS } from '@lib/utils/constants'
import { EGVRecord, MENU_OPTION } from '@models/index'

export enum EGV_STATUS {
  URGENT_LOW = 'Urgent Low',
  LOW = 'Low',
  URGENT_HIGH = 'Urgent High',
  HIGH = 'High',
  CHECK_SENSOR = 'Check Sensor',
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}

export const egvStatus = (
  egv: EGVRecord | undefined,
  now: number,
  lowthreshold: number,
  highthreshold: number
): EGV_STATUS => {
  if (
    egv === undefined ||
    now - egv.systemtime.getTime() > TEN_MINUTES_MILLISECONDS
  ) {
    return EGV_STATUS.CHECK_SENSOR
  } else if (egv.value < 60) {
    return EGV_STATUS.URGENT_LOW
  } else if (egv.value < lowthreshold) {
    return EGV_STATUS.LOW
  } else if (egv.value > 400) {
    return EGV_STATUS.URGENT_HIGH
  } else if (egv.value > highthreshold) {
    return EGV_STATUS.HIGH
  } else {
    return EGV_STATUS.ACTIVE
  }
}

export const egvStatusStyle = (
  status: string,
  theme: Theme
): React.CSSProperties => {
  switch (status) {
    case EGV_STATUS.CHECK_SENSOR:
    case EGV_STATUS.URGENT_HIGH:
    case EGV_STATUS.LOW:
    case EGV_STATUS.URGENT_LOW:
      return {
        color: theme.egvAlert.color,
        background: theme.egvAlert.backgroundColor,
        borderColor: theme.egvAlert.borderColor,
      } as CSSProperties
    case EGV_STATUS.HIGH:
      return {
        color: theme.egvWarn.color,
        background: theme.egvWarn.backgroundColor,
        borderColor: theme.egvWarn.borderColor,
      } as CSSProperties
    case EGV_STATUS.INACTIVE:
      return {
        color: theme.egvInactive.color,
        background: theme.egvInactive.backgroundColor,
        borderColor: theme.egvInactive.borderColor,
      } as CSSProperties
    default:
      return {
        color: theme.egvNormal.color,
        background: theme.egvNormal.backgroundColor,
        borderColor: theme.egvNormal.borderColor,
      } as CSSProperties
  }
}

export const menuOptions = (
  egvStatus: string,
  alarm: boolean
): MENU_OPTION[] => {
  let options = []
  if (egvStatus === EGV_STATUS.INACTIVE) {
    options = [MENU_OPTION.ACTIVATE, MENU_OPTION.DISCHARGE]
  } else {
    options = [MENU_OPTION.INACTIVATE]
  }

  if (alarm && egvStatus !== EGV_STATUS.INACTIVE) {
    options = [...options, MENU_OPTION.ACKNOWLEDGE]
  }

  return options
}
