import React, { useState } from 'react'
import Divider from '@material-ui/core/Divider'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import {
  Button,
  Grid,
  IconButton,
  createStyles,
  makeStyles,
  useTheme,
  Theme,
  Typography,
} from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogActions from '@material-ui/core/DialogActions'
import CloseIcon from '@material-ui/icons/Close'

import { INewParticipant } from '@models/form.models'
import { IMetaData } from '@models/index'
import useDashboardStyles from '@components/GlobalStyles'

type AddParticipantFormProps = {
  metadata: IMetaData[]
  onCancel: () => void
  onSubmit: (data: INewParticipant) => void
}

export default function AddParticipantForm(props: AddParticipantFormProps) {
  const theme = useTheme()
  const classes = useDashboardStyles(theme)
  const [error, setError] = useState('')
  const [participant, setParticipant] = useState<INewParticipant>({
    metas: Array(props.metadata.length).fill(''),
    condition: '',
  })

  const updateMeta =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setParticipant((currentParticipant) => {
        const copy = { ...currentParticipant }
        copy.metas[index] = event.target.value
        return copy
      })
    }

  const updateCondition = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    setParticipant((currentParticipant) => ({
      ...currentParticipant,
      condition: event.target.value as string,
    }))
  }

  const submit = () => {
    setError('')
    const validMetas = participant.metas.reduce(
      (acc, m) => acc && m !== '',
      true
    )
    if (validMetas && participant.condition !== '') {
      props.onSubmit(participant)
    } else {
      setError('Please enter a value for each field')
    }
  }

  return (
    <React.Fragment>
      <MuiDialogTitle>
        <Typography style={{ color: theme.palette.text.secondary }}>
          Add a New Participant
        </Typography>
        <IconButton
          className={classes.closeButton}
          aria-label="close"
          onClick={props.onCancel}
        >
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>
      <Divider />
      <Grid container justify="space-around" alignItems={'center'}>
        {props.metadata.map((m, i) => (
          <Grid item xs={9} key={m.displayname} style={{ marginTop: 10 }}>
            <TextField
              required
              variant="outlined"
              // style={{ display: 'block', margin: 10 }}
              // key={m.displayname}
              id={m.displayname}
              label={m.displayname}
              value={participant.metas[i]}
              onChange={updateMeta(i)}
              className={classes.userTextField}
            />
          </Grid>
        ))}
        <Grid item xs={9} style={{ marginTop: 10 }}>
          <TextField
            select
            label="Condition"
            helperText="Please select a diabetic condition"
            id="condition-input"
            value={participant.condition}
            onChange={updateCondition}
            className={classes.userTextField}
          >
            <MenuItem key="N/A" value="N/A">
              None
            </MenuItem>
            <MenuItem key="T1D" value="T1D">
              Type 1
            </MenuItem>
            <MenuItem key="T2D" value="T2D">
              Type 2
            </MenuItem>
          </TextField>
        </Grid>
      </Grid>
      <MuiDialogActions>
        <Button
          onClick={props.onCancel}
          style={{ marginRight: 10 }}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button onClick={submit} color="primary" variant="contained">
          Submit
        </Button>
        {error !== '' && (
          <div style={{ color: 'red', fontSize: '11px' }}>{error}</div>
        )}
      </MuiDialogActions>
    </React.Fragment>
  )
}
