import React, { useMemo } from 'react'
import Button from '@material-ui/core/Button'
import { DataGrid, GridCellParams, GridColumns } from '@material-ui/data-grid'
import { ISiteDetails } from '@models/index'

interface SitesListViewProps {
  sites: Array<ISiteDetails>
  onDelete: (id: string) => void
}

export const SitesListView: React.FunctionComponent<SitesListViewProps> = ({
  sites,
  onDelete,
}) => {
  const columnsDefinition = useMemo(() => {
    const columns: GridColumns = [
      {
        field: 'siteId',
        headerName: 'Site Id',
        width: 200,
      },
      {
        field: 'title',
        headerName: 'Title',
        width: 200,
      },
      {
        field: 'userCount',
        headerName: 'Users',
        width: 200,
      },
      {
        field: 'delete',
        headerName: 'Delete',
        width: 200,
        renderCell: (params: GridCellParams) => {
          const siteId = params.getValue(params.id, 'siteId') as string
          return (
            <Button
              color="primary"
              variant="contained"
              style={{
                fontSize: 11,
                textTransform: 'initial',
                width: '90%',
              }}
              onClick={() => onDelete(siteId)}
            >
              Delete
            </Button>
          )
        },
      },
    ]
    return columns
  }, [onDelete])

  if (sites.length === 0) {
    return <div>No Sites</div>
  }

  return (
    <DataGrid
      rows={sites}
      columns={columnsDefinition}
      hideFooter
      density="compact"
      autoHeight
    />
  )
}
