import {
  EGVRecord,
  IAcknowledgement,
  IAlarm,
  IDashboardUserDetails,
  IParticipantRecord,
  ISiteDetails,
  ISiteConfig,
  IUserConfig,
} from '@models/index'
import { CUSTOM_SORT_OPTIONS } from '@models/sortOptions.models'

export enum ActionTypes {
  ALL_EGVS = 'ALL_EGVS',
  ALL_ACKNOWLEDGEMENTS = 'ALL_ACKNOWLEDGEMENTS',
  NEW_EGVS = 'NEW_EGVS',
  NEW_ACKNOWLEDGEMENT = 'NEW_ACKNOWLEDGEMENT',
  ALARM_UPDATE = 'ALARM_UPDATE',
  PARTICIPANT_UPDATE = 'PARTICIPANT_UPDATE',
  SITE_CONFIG_UPDATE = 'SITE_CONFIG_UPDATE',
  USER_CONFIG_UPDATE = 'USER_CONFIG_UPDATE',
  SET_TOAST = 'SET_TOAST',
  SET_ERROR_TOAST = 'SET_ERROR_TOAST',
  HIDE_TOAST = 'HIDE_TOAST',
  TICK = 'TICK',
  SET_SORT_BY = 'SET_SORT_BY',
  TRIGGER_ALARM_ON = 'TRIGGER_ALARM_ON',
  TRIGGER_ALARM_OFF = 'TRIGGER_ALARM_OFF',
  SHOW_DISCHARGE_DIALOG = 'SHOW_DISCHARGE_DIALOG',
  HIDE_DISCHARGE_DIALOG = 'HIDE_DISCHARGE_DIALOG',
  ALL_SITES = 'ALL_SITES',
  NEW_SITE = 'NEW_SITE',
  DELETE_SITE = 'DELETE_SITE',
  ALL_USERS = 'ALL_USERS',
  NEW_USER = 'NEW_USER',
}

interface ITriggerAlarmOn {
  type: ActionTypes.TRIGGER_ALARM_ON
  payload: string
}

interface ITriggerAlarmOff {
  type: ActionTypes.TRIGGER_ALARM_OFF
  payload: string
}

interface ITickAction {
  type: ActionTypes.TICK
  payload: number
}

interface IAllAcknowledgementsAction {
  type: ActionTypes.ALL_ACKNOWLEDGEMENTS
  payload: IAcknowledgement[]
}

interface INewAcknowledgementAction {
  type: ActionTypes.NEW_ACKNOWLEDGEMENT
  payload: IAcknowledgement
}

interface IAlarmUpdateAction {
  type: ActionTypes.ALARM_UPDATE
  payload: Record<string, IAlarm[]>
}

interface ISiteConfigUpdateAction {
  type: ActionTypes.SITE_CONFIG_UPDATE
  payload: ISiteConfig
}

interface IParticipantUpdateAction {
  type: ActionTypes.PARTICIPANT_UPDATE
  payload: Array<IParticipantRecord>
}

interface IAllEgvsAction {
  type: ActionTypes.ALL_EGVS
  payload: Record<string, EGVRecord[]>
}

interface INewEgvsAction {
  type: ActionTypes.NEW_EGVS
  payload: Record<string, EGVRecord[]>
}

export interface IUserConfigUpdateAction {
  type: ActionTypes.USER_CONFIG_UPDATE
  payload: IUserConfig
}

export interface ISetToastAction {
  type: ActionTypes.SET_TOAST
  payload: string
}

export interface ISetErrorToastAction {
  type: ActionTypes.SET_ERROR_TOAST
  payload: string
}

export interface IHideToastAction {
  type: ActionTypes.HIDE_TOAST
  payload: undefined
}

export interface ISetSortBy {
  type: ActionTypes.SET_SORT_BY
  payload: CUSTOM_SORT_OPTIONS
}

export interface IShowDischargeDialog {
  type: ActionTypes.SHOW_DISCHARGE_DIALOG
  payload: string
}

export interface IHideDischargeDialog {
  type: ActionTypes.HIDE_DISCHARGE_DIALOG
}
export interface IAllSitesAction {
  type: ActionTypes.ALL_SITES
  payload: ISiteDetails[]
}
export interface INewSiteAction {
  type: ActionTypes.NEW_SITE
  payload: ISiteDetails
}
export interface IDeleteSiteAction {
  type: ActionTypes.DELETE_SITE
  payload: string
}
export interface IAllUsersAction {
  type: ActionTypes.ALL_USERS
  payload: IDashboardUserDetails[]
}
export interface INewUserAction {
  type: ActionTypes.NEW_USER
  payload: IDashboardUserDetails
}

export type Action =
  | ITickAction
  | IAlarmUpdateAction
  | IUserConfigUpdateAction
  | ISiteConfigUpdateAction
  | INewEgvsAction
  | IAllEgvsAction
  | IParticipantUpdateAction
  | ISetToastAction
  | ISetErrorToastAction
  | IHideToastAction
  | ISetSortBy
  | IShowDischargeDialog
  | IHideDischargeDialog
  | IAllAcknowledgementsAction
  | INewAcknowledgementAction
  | ITriggerAlarmOn
  | ITriggerAlarmOff
  | IAllUsersAction
  | INewUserAction
  | IAllSitesAction
  | INewSiteAction
  | IDeleteSiteAction

export const alarmOn = (participantId: string): ITriggerAlarmOn => ({
  type: ActionTypes.TRIGGER_ALARM_ON,
  payload: participantId,
})

export const alarmOff = (participantId: string): ITriggerAlarmOff => ({
  type: ActionTypes.TRIGGER_ALARM_OFF,
  payload: participantId,
})

export const tickAction = (timestamp: number): ITickAction => ({
  type: ActionTypes.TICK,
  payload: timestamp,
})

export const alarmUpdateAction = (
  alarms: Record<string, IAlarm[]>
): IAlarmUpdateAction => ({
  type: ActionTypes.ALARM_UPDATE,
  payload: alarms,
})

export const siteConfigUpdateAction = (
  siteConfig: ISiteConfig
): ISiteConfigUpdateAction => ({
  type: ActionTypes.SITE_CONFIG_UPDATE,
  payload: siteConfig,
})

export const participantUpdateAction = (
  participants: Array<IParticipantRecord>
): IParticipantUpdateAction => ({
  type: ActionTypes.PARTICIPANT_UPDATE,
  payload: participants,
})

export const allEgvsAction = (
  egvs: Record<string, EGVRecord[]>
): IAllEgvsAction => ({
  type: ActionTypes.ALL_EGVS,
  payload: egvs,
})

export const newEgvsAction = (
  egvs: Record<string, EGVRecord[]>
): INewEgvsAction => ({
  type: ActionTypes.NEW_EGVS,
  payload: egvs,
})

export const allAcknowledgementsAction = (
  acknowledgements: IAcknowledgement[]
): IAllAcknowledgementsAction => ({
  type: ActionTypes.ALL_ACKNOWLEDGEMENTS,
  payload: acknowledgements,
})

export const newAcknowledgementAction = (
  acknowledgement: IAcknowledgement
): INewAcknowledgementAction => ({
  type: ActionTypes.NEW_ACKNOWLEDGEMENT,
  payload: acknowledgement,
})

export const setUserConfig = (
  userConfig: IUserConfig
): IUserConfigUpdateAction => ({
  type: ActionTypes.USER_CONFIG_UPDATE,
  payload: userConfig,
})

export const setToast = (message: string): ISetToastAction => ({
  type: ActionTypes.SET_TOAST,
  payload: message,
})

export const setErrorToast = (message: string): ISetErrorToastAction => ({
  type: ActionTypes.SET_ERROR_TOAST,
  payload: message
})

export const hideToast = (): IHideToastAction => ({
  type: ActionTypes.HIDE_TOAST,
  payload: undefined,
})

export const setSortBy = (sortBy: CUSTOM_SORT_OPTIONS): ISetSortBy => ({
  type: ActionTypes.SET_SORT_BY,
  payload: sortBy,
})

export const showDischargeDialog = (id: string): IShowDischargeDialog => ({
  type: ActionTypes.SHOW_DISCHARGE_DIALOG,
  payload: id,
})

export const hideDischargeDialog = (): IHideDischargeDialog => ({
  type: ActionTypes.HIDE_DISCHARGE_DIALOG,
})

export const allSitesAction = (sites: ISiteDetails[]): IAllSitesAction => ({
  type: ActionTypes.ALL_SITES,
  payload: sites,
})

export const newSiteAction = (site: ISiteDetails): INewSiteAction => ({
  type: ActionTypes.NEW_SITE,
  payload: site,
})

export const deleteSiteAction = (siteId: string): IDeleteSiteAction => ({
  type: ActionTypes.DELETE_SITE,
  payload: siteId
})

export const allUsersAction = (
  users: IDashboardUserDetails[]
): IAllUsersAction => ({
  type: ActionTypes.ALL_USERS,
  payload: users,
})

export const newUserAction = (user: IDashboardUserDetails): INewUserAction => ({
  type: ActionTypes.NEW_USER,
  payload: user,
})
