import { useContext } from "react"
import { DataContext } from "../data.provider"

export const useGlobalState = () => {
  const ctx = useContext(DataContext)
  if (!ctx) {
    throw new Error('Cannot access state outside of DataProvider context.')
  }

  const { state, dispatch } = ctx

  return {
    state,
    dispatch
  }
}

export const useParticipantMap = () => {
  const { state } = useGlobalState()

  const { participantData } = state

  return participantData
}
