import React, { useCallback } from 'react'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import { MENU_OPTION } from '@models/index'
import useParticipantActions from '@providers/data/hooks/useDataRequests'
import useDischarge from '@providers/data/hooks/useDischarge'
import { useTheme } from '@material-ui/core'

const ITEM_HEIGHT = 48

interface IParticipantActionMenuProps {
  id: string
  menuOptions: string[]
  onEdit?: () => void
  color?: string
}

const ParticipantActionMenu: React.FunctionComponent<IParticipantActionMenuProps> =
  ({ id, menuOptions, onEdit, color }) => {
    const theme = useTheme()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const { inactivate, reactivate, acknowledge } = useParticipantActions()
    const { showDialog } = useDischarge()
    const action = useCallback(
      (menuOption: string) => {
        switch (menuOption) {
          case MENU_OPTION.INACTIVATE:
            return inactivate(id)
          case MENU_OPTION.ACTIVATE:
            return reactivate(id)
          case MENU_OPTION.DISCHARGE:
            return showDialog(id)
          case MENU_OPTION.ACKNOWLEDGE:
            return acknowledge(id)
          case 'Edit': {
            if (onEdit) {
              onEdit()
            }
            return
          }
          default:
            return
        }
      },
      [id, inactivate, reactivate, acknowledge, showDialog, onEdit]
    )
    const open = Boolean(anchorEl)

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation()
      setAnchorEl(event.currentTarget)
    }

    const handleClose = (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation()
      setAnchorEl(null)
    }

    const handleAction = (event: React.MouseEvent<HTMLElement>) => {
      const { value } = event.currentTarget.dataset
      if (value) {
        action(value)
      }
      handleClose(event)
    }

    return (
      <div>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
          style={{
            color: color,
          }}
        >
          <MoreHorizIcon />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: '20ch',
            },
          }}
        >
          {menuOptions.map((option) => (
            <MenuItem
              key={option}
              value={option}
              data-value={option}
              onClick={handleAction}
            >
              {option}
            </MenuItem>
          ))}
        </Menu>
      </div>
    )
  }

export default ParticipantActionMenu
