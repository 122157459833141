import React, { useEffect, useState } from 'react'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogActions from '@material-ui/core/DialogActions'
import CloseIcon from '@material-ui/icons/Close'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import {
  Button,
  TextField,
  Dialog,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from '@material-ui/core'
import { REACT_APP_SANDBOX } from '@config/env'
import { generatePassword } from '@lib/utils/password'
import useDashboardStyles from '@components/GlobalStyles'

interface ICreateSiteModalProps {
  open: boolean
  addSite: (
    siteId: string,
    username: string,
    password: string,
    sandbox?: boolean
  ) => void
  handleClose: () => void
}

export default function CreateSiteModalView({
  open,
  addSite,
  handleClose,
}: ICreateSiteModalProps) {
  const theme = useTheme()
  const classes = useDashboardStyles(theme)

  // create state variables for each input
  const [siteId, setSiteId] = useState('')
  const [adminUsername, setAdminUsername] = useState('')
  const [password, setPassword] = useState('')
  const [sandbox, setSandbox] = useState(false)

  useEffect(() => {
    setSiteId('')
    setAdminUsername('')
    setPassword(generatePassword())
    setSandbox(false)
  }, [open])

  const handleSiteIdChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSiteId(event.target.value)
    const adminName = `admin-${event.target.value}`
    setAdminUsername(adminName.toLowerCase())
  }

  const handleSandboxToggle = () => {
    if (REACT_APP_SANDBOX) {
      setSandbox(!sandbox)
    }
  }

  const handleSubmit = (event: React.MouseEvent) => {
    event.preventDefault()
    addSite(siteId, adminUsername, password, sandbox)
  }

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <MuiDialogTitle>
          <Typography style={{ color: theme.palette.text.secondary }}>
            Add New Site
          </Typography>
          {handleClose ? (
            <IconButton
              className={classes.closeButton}
              aria-label="close"
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </MuiDialogTitle>
        <hr
          color={theme.palette.grey[500]}
          style={{ width: '100%', borderTop: '3pt', margin: 0 }}
        ></hr>
        <Grid container justify="space-around" alignItems={'center'}>
          <Grid item xs={9} style={{ marginTop: 10 }}>
            <TextField
              label="Site ID"
              variant="outlined"
              required
              value={siteId}
              className={classes.siteTextField}
              onChange={(e) => handleSiteIdChange(e)}
            />
          </Grid>
          <Grid item xs={9} style={{ marginTop: 10 }}>
            <TextField
              label="Site Admin Username"
              variant="outlined"
              required
              aria-readonly
              value={adminUsername}
              className={classes.siteTextField}
              title="Auto generated.  You cannot change this value manually."
            />
          </Grid>
          <Grid item xs={9} style={{ marginTop: 10 }}>
            <TextField
              label="Password"
              variant="outlined"
              required
              aria-readonly
              value={password}
              className={classes.siteTextField}
              title="WARNING: Lost passwords will need to be reset by a Dexcom Engineer manually!"
            />
          </Grid>
          {REACT_APP_SANDBOX === 'true' && (
            <Grid item xs={9} style={{ marginTop: 10 }}>
              <FormControlLabel
                control={
                  <Switch
                    name="sandbox-toggle"
                    onChange={handleSandboxToggle}
                    checked={sandbox}
                    color="primary"
                  />
                }
                label="Initialize Sandbox Participants"
              />
            </Grid>
          )}
        </Grid>

        <MuiDialogActions>
          <Button onClick={handleClose} color="default" variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Create Site
          </Button>
        </MuiDialogActions>
      </Dialog>
    </React.Fragment>
  )
}
