import React from 'react'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import { ISiteConfig } from '@models/index'
import Spinner from '@components/Spinner/Spinner'
import useSiteConfig from '@providers/data/hooks/useSiteConfig'
import useParticipantActions from '@providers/data/hooks/useDataRequests'
import useToast from '@providers/data/hooks/useToast'
import GlobalSettings from './GlobalSettings'
import ParticipantSettings from './ParticipantSettings'
import AlarmSettings from './AlarmSettings'
import EventsSettings from './EventsSettings'

function Settings(): JSX.Element {
  const { siteConfig } = useSiteConfig()
  const { updateSiteConfig } = useParticipantActions()
  const { createToast } = useToast()
  const onsave = (settings: ISiteConfig) => {
    const newConfig = {
      ...siteConfig,
      ...settings,
    }
    updateSiteConfig(newConfig)
      .then(() => {
        createToast('Settings Saved Successfully!')
      })
      .catch((e) => {
        console.log(e)
        createToast('Error - Could Not Save Settings', true)
      })
  }

  if (!siteConfig) {
    return <Spinner />
  }

  return (
    <Grid container spacing={2}>
      <Grid item sm={12} md={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card elevation={1}>
              <GlobalSettings {...siteConfig} onsave={onsave} />
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card elevation={1}>
              <ParticipantSettings metas={siteConfig.metas} onsave={onsave} />
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card elevation={1}>
              <AlarmSettings {...siteConfig} onsave={onsave} />
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={12} md={6}>
        <EventsSettings {...siteConfig} onsave={onsave} />
      </Grid>
    </Grid>
  )
}

export default Settings
