import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTheme, Typography, Dialog, Paper, Grid } from '@material-ui/core'

import Graph from '@components/Graph/Graph'
import Spinner from '@components/Spinner/Spinner'
import DischargeDialog from '@components/Discharge/DischargeDialog'
import useSiteConfig from '@providers/data/hooks/useSiteConfig'
import useDataRequests from '@providers/data/hooks/useDataRequests'

import { useParticipantById } from './useParticipantById'
import ParticipantDetails from './ParticipantDetails'
import EventsCard from './Events'
import CommentsCard from './CommentsCard'
import CommentForm from './CommentForm'
import MetadataFormModal from './MetadataFormModal'

export default function ParticipantView() {
  const { id } = useParams<{ id: string }>()
  const { siteConfig } = useSiteConfig()
  const { addComment: addCommentAction } = useDataRequests()
  const [editFormOpen, setEditFormOpen] = useState(false)
  const [commentFormOpen, setCommentFormOpen] = useState(false)
  const participantInfo = useParticipantById(id)
  const theme = useTheme()

  const addComment = () => {
    setCommentFormOpen(true)
  }

  const submitComment = (comment: string) => {
    addCommentAction(id, comment).then(() => {
      setCommentFormOpen(false)
    })
  }

  const cancelComment = () => {
    setCommentFormOpen(false)
  }

  const editMetadata = () => {
    setEditFormOpen(true)
  }

  if (!participantInfo || !siteConfig) {
    return <Spinner />
  }

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            style={{ fontSize: 48, color: theme.palette.text.secondary }}
          >
            {participantInfo.metadata[0]}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={5} lg={4}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Paper
                    elevation={1}
                    style={{ backgroundColor: theme.palette.background.paper }}
                  >
                    <ParticipantDetails
                      participant={participantInfo}
                      siteConfig={siteConfig}
                      onMetadataUpdate={editMetadata}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper
                    elevation={1}
                    style={{ backgroundColor: theme.palette.background.paper }}
                  >
                    <EventsCard
                      timeInRange={participantInfo.timeInRange}
                      timeHypo={participantInfo.timeHypo}
                      timeHyper={participantInfo.timeHyper}
                      numberHypoEvents={participantInfo.numberOfHypoEvents}
                      numberHyperEvents={participantInfo.numberOfHyperEvents}
                    />
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={7} lg={8}>
              <Paper
                elevation={1}
                style={{
                  minHeight: '100%',
                  backgroundColor: theme.palette.background.paper,
                }}
              >
                <CommentsCard
                  onAdd={addComment}
                  comments={participantInfo.comments}
                  events={participantInfo.events}
                />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Paper
            elevation={1}
            style={{
              padding: 10,
              backgroundColor: theme.palette.background.paper,
            }}
          >
            <Graph
              timePicker
              egvs={participantInfo.allEgvs}
              highThresh={siteConfig.highalertthreshold}
              lowThresh={siteConfig.lowalertthreshold}
            />
          </Paper>
        </Grid>
      </Grid>
      <Dialog
        open={commentFormOpen}
        onClose={cancelComment}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <CommentForm onSubmit={submitComment} onCancel={cancelComment} />
      </Dialog>
      <MetadataFormModal
        setEditFormOpen={setEditFormOpen}
        editFormOpen={editFormOpen}
        siteConfig={siteConfig}
        participantInfo={participantInfo}
      />
      <DischargeDialog />
    </React.Fragment>
  )
}
