export interface IDataState {
  participants: IParticipantRecord[]
  siteConfig: ISiteConfig
  userConfig: IUserConfig
}

export interface ISession {
  startdate: Date
  enddate: Date
}

export interface EGVRecord {
  systemtime: Date
  displaytime: Date
  realtimevalue: number
  smoothedvalue: number
  status: string
  trend: string
  trendrate: string
  value: number
  participantid: string
}
export interface IMetaData {
  meta_id: number
  displayname: string
}
export interface IParticipantData {
  id: string
  status: string
  sessionEnd: string
  latestEgv: number
  trend: string
  received: string
  allEgvs: EGVRecord[]
  alarms: IAlarm[]
  metadata: string[]
  condition: string
  percentTimeInRange: number
  percentTimeOutOfRange: number
  timeInRange: number
  timeHyper: number
  timeHypo: number
  events: IEgvEventRecord[]
  numberOfHyperEvents: number
  numberOfHypoEvents: number
  endDate: number
  actions: string[]
  alarm: boolean
  alarmTrigger: boolean
  lastAlarmTrigger: number
  comments: IComment[]
}

export interface IParticipantGrouping {
  priority: IParticipantData[]
  active: IParticipantData[]
  inactive: IParticipantData[]
}

export interface IComment {
  body: string
  date: Date
  submitter: string
}

export interface IParticipantRecord {
  id: string
  metadata: string[] | null
  condition: string
  sessions: ISession[] | null
  status: string
  enddate: string
  aboverangecount: number
  belowrangecount: number
  egvcount: number
  events: IEgvEventRecord[] | null
  urgentlowcount: number
  withinrangecount: number
  comments: IComment[] | null
}

export interface IDashboardUserDetails {
  id: string
  username: string
  role: string
  siteId: string
  isCurrentUser?: boolean
}
export interface IEgvEventRecord {
  startdate: Date
  enddate: Date
  type: string
}

export interface IUserConfig {
  dashboardView: string
}

export interface ISiteConfig {
  metas: Array<IMetaData>
  highalertthreshold: number
  highaudiblealarmsenabled: boolean
  higheventthreshold: number
  hypereventtime: number
  hypoeventtime: number
  lowalertthreshold: number
  lowaudiblealarmsenabled: boolean
  loweventthreshold: number
  mineventtime: number
  signallossaudiblealarmsenabled: boolean
  supportphone: string
  title: string
}

export interface AddParticipantResponse {
  id: string
}

export enum DashboardView {
  LIST_VIEW = 'listView',
  CARD_VIEW = 'cardView',
  GRAPH_VIEW = 'graphView',
}

export interface IAlarm {
  id: string
  participantId: string
  siteId: string
  expiration: Date
  type: string
  canceled: boolean
}

export interface IAcknowledgement {
  userId: string
  siteId: string
  participantId: string
  timestamp: Date
  alarmId: string
}

export enum MENU_OPTION {
  ACKNOWLEDGE = 'Acknowledge',
  INACTIVATE = 'Inactivate',
  ACTIVATE = 'Activate',
  DISCHARGE = 'Discharge',
}

export enum ROLE_OPTION {
  ADMIN = 'Admin',
  CLINICIAN = 'Clinician',
  NURSE = 'Nurse',
}

export interface ISiteDetails {
  id: string
  siteId: string
  userCount: number
  adminUsername: string
  title: string
}
